<template>
  <div class="route-content">
    <div class="about">
      <h1 class="big-title">{{ $t('about') }}</h1>
      <p><span class="bold">Sublliure</span>.cat és una iniciativa que es proposa millorar i preservar la situació del català en l’àmbit de les versions originals subtitulades.</p>
      <p>Aquesta pàgina web ofereix dos serveis diferents i complementaris, totalment gratuïts:</p>
      <p>El primer servei és un <router-link to="search">banc de dades</router-link> que té com a objectiu <span class="bold">aplegar tots els subtítols disponibles en català</span> i posar-los a disposició de tothom. Inclou els subtítols que trobem publicats en un lloc o altre, els que generem els responsables de Sublliure i els que hi aportin totes les persones que vulguin col·laborar amb el projecte. Totes les pel·lícules i sèries referenciades tenen la imatge identificativa i un enllaç a la fitxa tècnica.</p>
      <p>El segon servei és una <router-link to="participa">aplicació</router-link> que permet la <span class="bold">traducció al català dels fitxers de subtítols</span> des de qualsevol idioma. És una eina potent i ràpida, que compta amb un sistema de traducció automàtica i que facilita molt la revisió i la correcció, sempre necessàries. Durant el procés de traducció el fitxer original i el traduït es guarden simultàniament i automàticament, de manera que es poden obrir i tancar tantes sessions com convingui. El fitxer català es pot descarregar en qualsevol moment del procés i, quan la traducció es dona per acabada, es pot publicar a la pàgina web anònimament o amb la signatura d’autor.</p>
    </div>
    <div id="banners">
      <Banner id="twitter-banner" title="Segueix-nos a Twitter!" subtitle="Segueix-nos a Twitter per rebre totes les novetats!" cta="Segueix" icon="fab fa-twitter" href="https://twitter.com/sublliure" target="_blank" style="background-color: #42b983" />
      <Banner id="contribute-banner" title="Participa!" subtitle="Anima't a participar traduint o pujant subtítols!" cta="Participa" icon="fas fa-globe-europe" href="participa" style="background-color: #2c3e50" />
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner'

export default {
  name: 'About',
  components: {
    Banner
  }
}

</script>

<style scoped lang='scss'>
.route-content {
  width: 100%;
  margin: 0 auto;

  .about{
    width: 80%;
    margin: 0 auto;
    padding: 32px 0 48px 0;
    @media (max-width: 768px) {
      width: 95%;
      padding: 0 0 16px 0;
    }
  }

  h1,h2,p {
    margin: 16px 0;
  }

  .bold{
    font-weight: bold;
  }

  .about a{
    color: #339167;
    text-decoration: underline;
    font-weight: bold;
  }

}
</style>
