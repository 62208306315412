<template>
  <form class="drop-box" v-bind:class="{ dragging: isDragging }"
  v-on:dragover="isDragging = true"
  v-on:dragenter="isDragging = true"
  v-on:dragleave="isDragging = false"
  v-on:drop="handleDrop($event.dataTransfer.files[0])"
  @click="$refs.fileInput.click()"
  @drag.prevent @dragstart.prevent @dragend.prevent @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
    <div id="box-content" class="opcio">
      <div>
        <h1>{{ title }}</h1>
        <p>{{ subtitle }}</p>
      </div>
      <i style="font-size: 3em" :class="'fas fa-' + icon"></i><br><br>
    </div>

    <input class="hidden" type="file" accept=".srt,.vtt,.ass" @change="handleDrop($event.target.files[0])" ref="fileInput" />
  </form>
</template>

<script>
export default {
  name: 'DropBox',
  props: {
    title: String,
    subtitle: String,
    icon: String
  },
  data: function () {
    return {
      isDragging: false
    }
  },
  methods: {
    handleDrop: function (file) {
      if (file !== undefined && file !== null) {
        this.$emit('onFileDropped', file)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.drop-box {

  #box-content{
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    padding: 54px 34px;
    border-radius: 4px;
    box-shadow: 0 0 4px #ccc;
    gap: 10px;
    box-sizing: border-box;
    border: 3px solid #ffffff;
    cursor: pointer;
    height: 100%;
    @media (max-width: 768px) {
      padding: 24px 14px;
    }
    &:hover, &.dragging{
      border: 3px dashed #00000022;
    }
  }

  .hidden {
    display: none;
  }
}
</style>
