<template>
  <div class="route-content">
    <h1 v-if="user !== null" class="big-title">{{ $t('message', {name: user ? user.name : ''}) }}</h1><br>
    <CardButton v-if="user !== null" :title="$t('my_subtitles')" :subtitle="$t('my_subtitles_description')" :to="{ name: 'UserProfile', params: { slug: this.user.slug} }" />
    <Login id="login" />
    <div class="or-separator" v-if="!authenticated">
      <div></div>
      <span>{{ $t('or') }}</span>
      <div></div>
    </div>
    <div id="social-login" v-if="!authenticated">
      <a href="/login/twitter" class="social-button twitter">
        <i class="fa fa-twitter"></i> Entra amb Twitter
      </a>
    </div>
  </div>
</template>

<script>

import Login from '@/components/Login'
import CardButton from '@/components/CardButton'
import { mapGetters } from 'vuex'

export default {
  name: 'LoginView',
  components: {
    Login,
    CardButton
  },
  data: function () {
    return {
    }
  },
  methods: {},
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    })
  }
}
</script>

<style scoped lang="scss">
.route-content{
  width: 50%;
  max-width: 500px;
  margin: 0 auto;
  padding-top: 40px;
  @media (max-width: 768px) {
    width: 95%;
    padding-top: 10px;
  }

  #login{
    background: white;
    padding: 20px 30px 30px 30px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
  }

  #social-login{
    margin-top: 8px;
    background: white;
    padding: 20px 30px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e4e4e4;

    .social-button{
      display: block;
      border-radius: 5px;
      text-align: center;
      padding: 10px;

      &.twitter{
        background: #1da1f2;
        color: white;
        font-weight: bold;
      }
    }

  }

  .or-separator{
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    :first-child, :last-child{
      width: 45%;
      height: 1px;
      background: #ccc;
    }
  }

}
</style>
