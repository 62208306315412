export function debounce (fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export function groupByArray (xs, key) {
  return xs.reduce(function (rv, x) {
    const v = key instanceof Function ? key(x) : x[key]
    const el = rv.find((r) => r && r.key === v)
    if (el) {
      el.values.push(x)
    } else {
      rv.push({ key: v, values: [x] })
    } return rv
  }, [])
}

export function getHtmlElementType (str) {
  // Create a div element and insert the string into it
  const div = document.createElement('div')
  div.innerHTML = str

  // Get the first child element of the div
  const firstChild = div.firstChild

  // If the first child is an element, return its tag name. Otherwise, return null.
  return firstChild && firstChild.nodeType === 1 ? firstChild.tagName : null
}
