<template>
  <div id="search-container">
    <div id="inner-container">
      <input type="text" :placeholder="$t('search')" v-model="searchQuery" @input="isSearching = searchQuery.length > 0" @focus="searchQuery=''" />
      <i v-if="searchQuery.length <= 0" id="search-icon" class="fas fa-search" @click="$router.replace('/search')"/>
      <i v-if="searchQuery.length > 0" id="search-icon" class="fas fa-times" @click="showsResults=false; results=[]; searchQuery= ''" />
    </div>
    <div id="results-container" v-if="showsResults && (searchQuery.length > 0 || isSearching)">
      <div id="results">
        <SearchResult v-for="result in results" :key="result.id" :media="result" v-on:click.native="showsResults=false; results=[]; searchQuery= ''" />
        <LoadingLogo v-if="isSearching" :height="50" style="padding: 22px 0" />
        <p v-if="results.length === 0 && !isSearching">{{ $t('no_results') + ' ' + searchQuery + '.' }}</p>
        <router-link class="see-all" v-if="results.length > 0" v-on:click.native="showsResults=false;results=[]" :to="'/search?query='+searchQuery">Veure tots els resultats</router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { search } from '@/libs/api/search.api'
import { debounce } from '@/libs/helpers'
import SearchResult from '@/components/SearchResult'
import LoadingLogo from '@/components/LoadingLogo'

const searchResultLimit = 5

export default {
  name: 'SearchBar',
  components: {
    SearchResult,
    LoadingLogo
  },
  data: function () {
    return {
      results: [],
      searchQuery: '',
      isSearching: false,
      showsResults: false
    }
  },
  watch: {
    searchQuery: debounce(function (newVal) {
      this.doSearch()
    }, 300)
  },
  methods: {
    doSearch: async function () {
      if (this.searchQuery.trim().length === 0) {
        return
      }
      this.showsResults = true
      this.isSearching = true
      const results = await search({ query: this.searchQuery, limit: searchResultLimit })
      this.results = results.data.data
      this.isSearching = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  #search-container {
    background: #2c3e50;
    width: 100%;

    #inner-container {
      width: 80%;
      max-width: 800px;
      margin: 16px auto;
      background: #FFFFFF22;
      align-items: center;
      position: relative;
      border-radius: 5px;

      @media (max-width: 768px) {
        width: 100%;
        margin: 0;
        border-radius: 0;
        background: none;
      }

      input {
        padding: 16px;
        border-radius: 5px;
        border: 0;
        font-size: 1.4em;
        display: block;
        font-family: 'Manrope', sans-serif;
        font-weight: 100;
        color: white;
        background: transparent;
        width: 100%;
        box-sizing: border-box;
        margin-top: 0;

        @media (max-width: 768px) {
          font-size: 1em;
          border-radius: 0;
        }

        &:focus {
          box-shadow: 0px 0px 0px 1px #FFFFFF66;
          @media (max-width: 768px) {
            box-shadow: none;
          }
        }

        &::placeholder {
          color: white;
        }

      }

      #search-icon {
        font-size: 1.2em;
        color: #FFFFFF88;
        position: absolute;
        right: 1px;
        top: 1px;
        padding: 20px;
        cursor: pointer;
        @media (max-width: 768px) {
          padding: 17px 20px;
        }
      }
    }
  }

  #results-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #00000066;
    left: 0;
    z-index: 1000;

    #results {
      width: 80%;
      max-width: 800px;
      padding: 16px;
      box-sizing: border-box;
      margin: 0 auto;
      background: white;
      box-shadow: 0px 0px 10px #00000066;
      border-radius: 0 0 10px 10px ;

      @media (max-width: 768px) {
        width: 100%;
        border-radius: 0;
      }

    }
  }

  .see-all{
    display: block;
    padding: 32px 10px;
    color: #999;
    border: 2px solid #ccc;
    border-radius: 4px;
    text-align: center;
    font-weight: bold;
  }
</style>
