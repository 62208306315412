<template>
  <div class="route-content">
    <ErrorMessage />
    <h1 class="big-title">{{ $t('send_reset_password') }}</h1><br>
      <div class="form-container" >
          <LoadingLogo v-if="loading"/>
          <form v-if="!loading" action="#" @submit.prevent="doReset">
            <input type="hidden" name="token" v-model="form.token">
            <div class="form-group row">
                <label for="email" class="col-md-4 col-form-label text-md-right">{{ $t('email') }}</label>
                <div class="col-md-6">
                    <input id="email" type="email" name="email" v-model="form.email" required autofocus>
                </div>
            </div>
            <div class="form-group row">
                <label for="password" class="col-md-4 col-form-label text-md-right">{{ $t('password') }}</label>
                <div class="col-md-6">
                    <input id="password" type="password" name="password" v-model="form.password" minlength="8" required>
                </div>
            </div>
            <div class="form-group row">
                <label for="password-confirm" class="col-md-4 col-form-label text-md-right">{{ $t('confirm_password') }}</label>
                <div class="col-md-6">
                    <input id="password-confirm" type="password" name="password_confirmation" v-model="form.password_confirmation" minlength="8" required>
                </div>
            </div>
            <div class="form-group row mb-0">
                <div class="col-md-6 offset-md-4">
                    <button class="cta-big" type="submit">{{$t('reset_password') }}</button>
                </div>
            </div>
        </form>
      </div>
  </div>
</template>

<script>
import LoadingLogo from '@/components/LoadingLogo'
import ErrorMessage from '@/components/ErrorMessage'
import { mapActions } from 'vuex'

export default {
  name: 'PasswordReset',
  components: {
    ErrorMessage,
    LoadingLogo
  },
  data: function () {
    return {
      loading: false,
      form: {
        email: this.$route.query.email,
        token: this.$route.params.token
      }
    }
  },
  methods: {
    ...mapActions({
      resetPassword: 'auth/resetPassword'
    }),
    async doReset () {
      this.loading = true
      await this.resetPassword(this.form)
      this.loading = false
    }
  },
  computed: {
  }
}
</script>

<style scoped lang="scss">
.route-content{
  width: 50%;
  margin: 0 auto;
  padding-top: 40px;
  @media (max-width: 768px) {
    width: 95%;
    padding-top: 10px;
  }

  .form-container{
    background: white;
    padding: 20px 30px 30px 30px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #e4e4e4;
  }

  label, input, button, textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  label, button{
    margin-top: 10px;
    &.cta-big{
      margin-top: 20px;
    }
  }
}
</style>
