import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import VueSanitize from 'vue-sanitize'
import i18n from './i18n'
import { Quasar, QVirtualScroll } from 'quasar'
import store from './store'
import axios from 'axios'
import './quasar'
import VueLazyload from 'vue-lazyload'

axios.defaults.withCredentials = true

Vue.use(Vuex)
Vue.use(VueSanitize)
Vue.use(VueLazyload)
Vue.use(Quasar, {
  components: {
    QVirtualScroll
  }
})

Vue.config.productionTip = false

store.dispatch('auth/me').then(() => {
  new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
  }).$mount('#app')
})
