<template>
  <q-virtual-scroll
      id="translation-table"
      v-if="original"
      type="table"
      :virtual-scroll-slice-size="60"
      :virtual-scroll-item-size="135"
      :virtual-scroll-sticky-size-start="0"
      :items="original"
      v-on:scroll.native="handleScroll"
    >
      <template v-slot:before>
        <thead>
          <th id="th-id">{{ $t("id") }}</th>
          <th id="th-start">{{ $t("start_time") }}</th>
          <th id="th-end">{{ $t("end_time") }}</th>
          <th id="th-original">
            <span>{{ $t("original") }}</span>
            <div class="header-actions">
              <button @click="$emit('uploadOriginalFile')" :title="$t('open_original')"><i class="fas fa-folder-open"></i> {{ $t("open") }}</button>
            </div>
          </th>
          <th id="th-translation">
            <span>{{ $t("translation") }}</span>
            <div class="header-actions">
              <button @click="$emit('uploadTranslationFile')" :title="$t('open_translation')"><i class="fas fa-folder-open"></i> {{ $t("open") }}</button>
            </div>
          </th>
        </thead>
      </template>

      <template v-slot="{ item: row, index }">
        <tr :key="index">
          <td class="td-id">{{ index + 1 }}</td>
          <td class="td-start">{{ srtTime(row.start) }}</td>
          <td class="td-end">{{ srtTime(row.end) }}</td>
          <td class="td-original">{{ row.text }}</td>
          <td class="td-translation" v-bind:class="{ edited: translatedData[index] ? translatedData[index].edited : false }" >
            <textarea :value="translatedData[index] ? translatedData[index].text : ''" v-on:focus="currentElementBeingEdited = {index: index, evt: $event}" v-on:blur="toggleEdited(index, $event)" />
            <div class="td-actions">
              <button class="borderless" @click="insertItem(index+1)" :title="$t('add_item_tooltip')"><i class="fas fa-plus"></i></button>
              <button class="borderless" @click="removeItem(index)" :title="$t('remove_item_tooltip')"><i class="fas fa-trash-alt"></i></button>
            </div>
          </td>
        </tr>
      </template>
    </q-virtual-scroll>
</template>

<script>
import { QVirtualScroll } from 'quasar'
import { toSrtTime } from 'subtitle'

export default {
  name: 'SubtitleTable',
  components: {
    QVirtualScroll
  },
  props: {
    original: Array,
    translation: Array
  },
  data: function () {
    return {
      currentElementBeingEdited: null,
      translatedData: this.translation
    }
  },
  watch: {
    translation: function (newVal, oldVal) {
      this.translatedData = Object.freeze(newVal)
    }
  },
  methods: {
    insertItem: function (atIndex) {
      var dataCopy = JSON.parse(JSON.stringify(this.translatedData))
      dataCopy.splice(atIndex, 0, { text: '', edited: false })
      this.translatedData = Object.freeze(dataCopy)
      this.$emit('update-translation', this.translatedData)
    },
    removeItem: function (atIndex) {
      var dataCopy = JSON.parse(JSON.stringify(this.translatedData))
      dataCopy.splice(atIndex, 1)
      this.translatedData = Object.freeze(dataCopy)
      this.$emit('update-translation', this.translatedData)
    },
    toggleEdited: function (index, evt) {
      const newText = evt.target.value
      if (newText !== undefined) {
        evt.target.parentElement.classList.add('edited')
        const dataCopy = JSON.parse(JSON.stringify(this.translatedData))
        dataCopy[index] = { text: newText, edited: true }
        this.translatedData = Object.freeze(dataCopy)
        this.$emit('update-translation', this.translatedData)
      }
    },
    handleScroll: function () {
      if (this.currentElementBeingEdited !== null && this.currentElementBeingEdited !== undefined) {
        this.toggleEdited(this.currentElementBeingEdited.index, this.currentElementBeingEdited.evt)
        this.currentElementBeingEdited = null
      }
    },
    srtTime: function (ms) {
      return toSrtTime(ms)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

table, th, td {
  border: 1px solid #666;
}

#translation-table{
  width: 100%;
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;

  td, th {
    padding: 8px 16px;
    box-sizing: border-box;
    background-clip: padding-box;
  }
  th {
    background: #efefef;
    background-clip: padding-box;
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0 1px 4px #00000099;
    .header-actions{
      position: absolute;
      top: 4px;
      right: 1px;
      button{
        margin-right: 2px;
      }
    }
  }
}
#th-id {
  width: 2%;
}
#th-start {
  width: 10%;
}
#th-end {
  width: 10%;
}
#th-original {
  width: 30%;
}
.td-translation {
  position: relative;
  padding: 0 !important;
  height: 100%;
  &.edited {
    background-color: #ddffdd;
  }

  textarea {
    width: 100%;
    border: none;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    padding: 8px 16px;
    box-sizing: border-box;
    background-color: transparent;
  }
  .td-actions{
    position: absolute;
    top: 1px;
    right: 0;
    background-color: #ffffff99;

    button {
      opacity: 0.5;
    }
  }
}

@media (max-width: 768px) {
  #translation-table{
    width: 100% !important;
    border: none;
    thead {
      display: none;
    }

    td {
      display: block;
      box-sizing: border-box;
    }

    tr {
      border-left: 1px solid;
      border-right: 1px solid;
      box-sizing: border-box;
      display: block;
    }

    td.td-original {
      clear: both;
      opacity: 0.7;
      border: none !important;
      border-bottom: 1px solid #ccc !important;
    }

    td.td-translation {
      border: none !important;
    }

    td.td-id {
        width: 16%;
        float: left;
        text-align: start;
        border: none;
        background-color: #f4f4f4;
        outline: 1px solid #000;
        padding-left: 8px;
        border-top: 1px solid;
    }

    td.td-end, td.td-start {
        width: 42%;
        float: left;
        border: none;
        outline: 1px solid #000;
        background-color: #f4f4f4;
        border-top: 1px solid;
    }

    .td-actions {
      top: unset;
      bottom: 1px;
    }
  }
}

</style>
