<template>
  <div class="route-content">
    <h1 class="big-title">{{ $t('participate_cta') }}</h1>
    <p>Anima't a participar en la nostra plataforma! Et donem dues opcions:</p>
    <div id="opcions">
      <DropBox
        class="opcio"
        @onFileDropped="launchTranslator"
        :title="$t('translate_cta_title')"
        :subtitle="$t('translate_cta_subtitle')"
        :icon="'folder-open'" />
      <DropBox
        class="opcio"
        @onFileDropped="handleFile"
        :title="$t('upload_cta_title')"
        :subtitle="$t('upload_cta_subtitle')"
        :icon="'upload'" />
    </div>
    <PublishFlow
      v-if="showPublishModal"
      :file="file"
      @launchTranslator="launchTranslator"
      @close="showPublishModal = false"
      @published="showPublishModal = false; $router.push('/')" />
    <div class="tmdb-attribution">
      <p>Aquest portal funciona gràcies a les dades de:</p>
      <p style="margin-top: 10px">
        <a href='https://www.themoviedb.org' target='_blank'>
          <img height="20" src="https://www.themoviedb.org/assets/2/v4/logos/v2/blue_long_1-8ba2ac31f354005783fab473602c34c3f4fd207150182061e425d366e4f34596.svg"/>
        </a>
      </p>
    </div>
  </div>
</template>

<script>

import DropBox from '@/components/DropBox'
import PublishFlow from '@/components/modals/PublishFlow'

export default {
  name: 'Participa',
  components: {
    DropBox,
    PublishFlow
  },
  data: function () {
    return {
      showPublishModal: false,
      showAuthModal: false,
      file: null,
      shouldLaunchTranslator: false
    }
  },
  methods: {
    handleFile: async function (file) {
      this.file = file
      this.showPublishModal = true
    },
    launchTranslator: function (file) {
      this.$router.push({ name: 'Translator', params: { file: file } })
    }
  }
}
</script>

<style scoped lang="scss">
.route-content{
  width: 80%;
  margin: 0 auto;
  padding-top: 40px;
  @media (max-width: 768px) {
    width: 95%;
    padding-top: 10px;
  }

  #opcions {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media (max-width: 768px) {
      flex-direction: column;
    }

    .opcio {
      flex: 1;
    }
  }

  .tmdb-attribution{
    text-align: center;
    padding-top: 40px;
  }

}
</style>
