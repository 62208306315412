import httpClient from './httpClient'

const END_POINT = '/search'

const search = (filters) => httpClient.get(END_POINT, { params: filters })

const getLanguages = () => httpClient.get('/languages')

export {
  search,
  getLanguages
}
