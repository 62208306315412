<template>
  <router-link class="card-button" :to="to">
    <div>
      <h1>{{ title }}</h1>
      <p>{{ subtitle }}</p>
    </div>
    <i :class="'fas fa-' + icon"></i>
  </router-link>
</template>

<script>
export default {
  name: 'CardButton',
  props: {
    title: String,
    subtitle: String,
    icon: {
      type: String,
      default: 'arrow-right'
    },
    to: Object
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
  padding: 54px 34px;
  border-radius: 4px;
  box-shadow: 0 0 4px #ccc;
  gap: 16px;
  box-sizing: border-box;
  border: 3px solid #ffffff;
  cursor: pointer;
  justify-content: space-between;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    padding: 24px 14px;
    h1 {
      font-size: 1.3em;
    }
  }
  &:hover, &.dragging{
    box-shadow: 0 0 10px #ccc;
  }
  i{
    font-size: 2em;
  }
}
</style>
