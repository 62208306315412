<template>
  <div class='route-content'>
    <div id="lists" v-if="latest.length > 0 && popular.length > 0">
      <div class="latest">
        <h1><i class="fas fa-clock"/>{{ $t('subtitles_latest') }}</h1>
        <SearchResult v-for='s in latest' :key="s.id+'latest'" :media="s.media" :subtitle="s" :showsEpisodeInfo="true" />
      </div>
      <div class="popular">
        <h1><i class="fas fa-fire-alt"/>{{ $t('subtitles_popular') }}</h1>
        <SearchResult v-for='m in popular' :key="m.id+'popular'" :media="m" />
      </div>
    </div>
    <div class="see-all-cta" v-if="latest.length > 0 && popular.length > 0" >
      <router-link class="see-all" to="/search">Veure tots els subtítols<i class="fas fa-search" style="margin-left:10px"/></router-link>
    </div>
    <LoadingLogo v-if="latest.length === 0 || popular.length === 0" style="padding-top: 100px" />
    <MediaCollection :filter="{ order_by: 'release_date' }" :title="$t('premiering')" />
    <MediaCollection :filter="{ order_by: 'year', order_direction: 'asc' }" :title="$t('classics')" />
    <MediaCollection :filter="{ excluded_language: 'en', order_by: 'created_at' }" :title="$t('non_english')" />
    <MediaCollection :filter="{ original_language: 'ja', genre: '16', order_by: 'year' }" :title="$t('anime')" />
    <div id="banners" v-if="latest.length > 0 && popular.length > 0">
      <Banner id="twitter-banner" title="Segueix-nos a Twitter!" subtitle="Segueix-nos a Twitter per rebre totes les novetats!" cta="Segueix" icon="fab fa-twitter" href="https://twitter.com/sublliure" target="_blank" style="background-color: #42b983" />
      <Banner id="contribute-banner" title="Participa!" subtitle="Anima't a participar traduint o pujant subtítols!" cta="Participa" icon="fas fa-globe-europe" href="/participa" style="background-color: #2c3e50" />
    </div>
  </div>
</template>

<script>
import SearchResult from '../components/SearchResult.vue'
import { getLatestSubtitles } from '@/libs/api/subtitle.api'
import { getPopularMedia } from '@/libs/api/media.api'
import LoadingLogo from '@/components/LoadingLogo'
import Banner from '@/components/Banner'
import MediaCollection from '@/components/MediaCollection'

export default {
  name: 'Home',
  components: {
    SearchResult,
    LoadingLogo,
    Banner,
    MediaCollection
  },
  data: function () {
    return {
      latest: [],
      popular: []
    }
  },
  beforeMount () {
    this.getLatest()
    this.getPopular()
  },
  methods: {
    getLatest: async function () {
      const res = await getLatestSubtitles(5)
      this.latest = res.data.data
    },
    getPopular: async function () {
      const res = await getPopularMedia(5)
      this.popular = res.data.data
    }
  }
}

</script>

<style scoped lang='scss'>
.route-content {
  padding-bottom: 0 !important;
  ul li {
    cursor: pointer
  }

  #lists {
    width: 80%;
    margin: 0 auto;
    display: flex;
    gap: 32px;

    h1 {
      font-weight: 300;
      font-size: 1.2em;
      text-transform: uppercase;
      margin: 30px 0 10px 0;
      @media (max-width: 768px) {
        margin-top: 20px;
      }

      i {
        padding-right: 8px;
      }
    }

    .popular{
      flex: 1;
    }

    .latest{
      flex: 1;
    }

    @media (max-width: 768px) {
      display: block;
      width: 95%;
    }
  }

  .see-all-cta {
    margin: 24px 0 36px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .see-all{
      padding: 16px 32px;
      color: white;
      border-radius: 29px;
      text-align: center;
      font-weight: bold;
      background: #339167;
      box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
    }
  }

}
</style>
