<template>
  <Modal @close="$emit('close')">
    <div slot="header">
      <h1>{{ $t('edit') }}</h1>
    </div>
    <div slot="body">
      <p class="filename"><i class="fas fa-file fa-fw"></i> {{ subtitle.original_file_name }}</p>
      <form v-if="!isUpdating" style="margin-bottom: 16px">
        <div v-if="mediaType === 'tv'">
          <p>{{ $t('season') }}</p>
          <input type="number" v-model="subtitle.season" />
          <p>{{ $t('episode') }}</p>
          <input type="number" v-model="subtitle.episode" />
        </div>
        <p>{{ $t('comment') }}</p>
        <textarea v-model="subtitle.comment" :placeholder="$t('add_comment')" maxlength="200" />
      </form>
      <LoadingLogo v-if="isUpdating" />
    </div>
    <div slot="footer" v-if="!isUpdating">
      <button class="cta-big" @click="saveSubtitle(subtitle)" :disabled="isUpdating">{{ $t('publish') }}</button>
      <p style="text-align: center; margin-top: 16px; color: red">
        <a class="link" @click="userDelete(subtitle)">{{ $t('delete_subtitle') }}</a>
      </p>
    </div>
  </Modal>
</template>

<script>
import Modal from './Modal.vue'
import LoadingLogo from '../LoadingLogo.vue'
import { updateSubtitle, deleteSubtitle } from '@/libs/api/subtitle.api'

export default {
  name: 'EditSubtitle',
  components: {
    Modal,
    LoadingLogo
  },
  props: {
    subtitle: Object,
    mediaType: String
  },
  data: function () {
    return {
      isUpdating: false
    }
  },
  methods: {
    saveSubtitle: async function (subtitle) {
      this.isUpdating = true
      try {
        await updateSubtitle(subtitle)
        this.$emit('updated', subtitle)
      } catch (err) {
        alert(err)
      }
      this.isUpdating = false
    },
    userDelete: async function (subtitle) {
      if (confirm(this.$t('delete_subtitle_confirmation')) === false) {
        return
      }
      this.isUpdating = true
      try {
        await deleteSubtitle(subtitle)
        this.$emit('deleted', subtitle)
      } catch (err) {
        alert(err)
      }
      this.isUpdating = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.buttons{
  display:flex;
  flex-direction: row;
  gap: 5px;
  button {
    flex: 1;
  }
}

label, input, button, textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  max-width: 100%;
  min-width: 100%;
  max-height: 60px;
  padding: 8px;
  box-sizing: border-box;
  resize: none;
}

.input-group-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  input[type=number] {
    width: 48px;
  }
}

.filename{
  padding: 8px 4px;
  margin-bottom: 8px;
  box-sizing: border-box;
  background: #e9f4ed;
  border-radius: 8px;
  word-wrap: anywhere;
}

</style>
