import { parse } from 'subtitle'
import jschardet from 'jschardet'

const parseSubtitleFile = async (file) => {
  const result = await readSubtitleFile(file)
  if (file.name.endsWith('srt') || file.name.endsWith('vtt') || file.type.indexOf('subrip') > 0) {
    const parsedData = parse(result)
    if (parsedData.length > 1 && Object.keys(parsedData[0]).length > 0) {
      return { parsed: true, data: parsedData }
    }
  }
  return { parsed: false, data: result }
}

const readSubtitleFile = async (file) => {
  if (file === undefined || file === null) {
    console.log('No file is selected')
    return
  }

  try {
    var text = await readFileAsync(file)
    const encoding = jschardet.detect(text)
    if (encoding.confidence === 1) {
      text = await readFileAsync(file, encoding.encoding)
      return text
    } else {
      return text
    }
  } catch (err) {
    throw Error(err)
  }
}

function readFileAsync (file, encoding) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (evt) => {
      resolve(evt.target.result)
    }
    reader.onerror = reject
    reader.readAsText(file, encoding)
  })
}

export { parseSubtitleFile }
