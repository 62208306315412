<template>
  <div id="app">
    <div id="headerbar" :class="{ bordered: hidesSearchBar }">
      <div id="logo-container" >
        <router-link to="/" style="display: inherit">
          <img id="logo-image" src="/logo_beta.png" height="44">
          <h1 id="logo-title">
            <span id="name">SUBLLIURE</span>
            <span id="domain">.cat</span>
          </h1>
        </router-link>
      </div>
      <ul id="navigation" v-bind:class="{ mobile: showsMobileMenu }">
        <li><router-link to="/" @click.native="showsMobileMenu = false">{{ $t('home') }}</router-link></li>
        <li><router-link to="/participa" @click.native="showsMobileMenu = false" class="participa-cta">{{ $t('participate_cta') }}</router-link></li>
        <li v-if="authenticated"><router-link to="/login" @click.native="showsMobileMenu = false" class="user-icon" :title="user.name"><i class="fa fa-user"/></router-link></li>
        <li v-else><router-link to="/login" @click.native="showsMobileMenu = false" class="user-icon" :title="$t('login')"><i class="fa fa-lock"/> {{ $t('login') }}</router-link></li>
      </ul>
      <i id="mobile-menu-icon" class="fas fa-bars" @click="showsMobileMenu = !showsMobileMenu"></i>
    </div>
    <SearchBar v-if="!hidesSearchBar"/>
    <router-view/>
    <div id="footer" v-if="$route.name !== 'Translator'">
      <img id="logo-image" src="/logo.png" height="34">
      <p>v{{ version }} - © 2023</p>
    </div>
  </div>
</template>

<script>

import SearchBar from './components/SearchBar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  metaInfo: function () {
    return {
      title: this.$t('page_title'),
      titleTemplate: '%s | ' + this.$t('page_subtitle'),
      meta: [
        { name: 'description', content: this.$t('page_description') }
      ]
    }
  },
  data: function () {
    return {
      showsMobileMenu: false,
      version: process.env.VUE_APP_VERSION
    }
  },
  computed: {
    hidesSearchBar () {
      return this.$route.name === 'Translator' || this.$route.name === 'Login' || this.$route.name === 'PasswordReset'
    },
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user'
    })
  },
  components: {
    SearchBar
  }
}
</script>

<style lang="scss">

$mainFont: 'Manrope';

* {
  margin: 0;
  padding: 0;
}

body {
  background: #f4f4f4;
}

#app {
  font-family: $mainFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .route-content {
    flex: 1;
    padding-bottom: 60px;
    @media (max-width: 768px) {
      padding-bottom: 20px;
    }
  }

  .big-title {
    font-weight: 300;
    font-size: 3em;
    @media (max-width: 768px) {
      font-size: 2em;
    }
  }

  a {
    &.link{
      color: #2c3e50;
      border-bottom: 1px solid;

      &:hover{
        border-bottom: 2px solid;
      }
    }
  }

  #headerbar {
    width: 100%;
    display: flex;
    padding: 35px 10%;
    box-sizing: border-box;
    background: white;
    position: relative;
    z-index: 100;
    align-items: center;
    flex: 0;

    &.bordered{
      border-bottom: 1px solid #e4e4e4;
    }

    @media (max-width: 768px) {
      padding: 20px 15px;
    }

    #logo-container{
      display: flex;
      flex: 1;

      #logo-image {
        cursor: pointer;
        height: 44px;
        @media (max-width: 768px) {
          height: 28px;
        }
      }

      #logo-title {
        cursor: pointer;
        margin-left: 20px;
        user-select: none;
        @media (max-width: 768px) {
          margin-left: 10px;
          font-size: 1.4em;
        }
        #domain, #subtitle {
          font-weight: 300;
          font-size: 1em;
          color: #999;
        }
        #subtitle {
          font-weight: 300;
          font-size: 0.5em;
          color: #999;
          position: relative;
          left: 20px;
        }
        #beta-tag{
          color: red;
        }
      }
    }

    #navigation{
      display: flex;
      align-items: center;
      padding-right: 10px;
      list-style: none;

      @media (max-width: 768px) {
        display: none;
      }

      &.mobile {
        display: flex;
        flex-direction: column;
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        background: white;
        top: 70px;
        left: 0;
        padding: 20px;
        box-shadow: 0 8px 8px #00000033;
        z-index: 101;
        border-top: 1px solid #ccc;

        li {
          padding: 4px 0;
          border: none;
          width: 100%;
          a {
            display: block;
            box-sizing: border-box;
            width: 100%;
            text-align: center;
          }
          &:hover{
            border: none;
            margin-bottom: 0;
          }
        }
      }

      li {
        float: left;
        margin-left: 40px;
        border-bottom: 1px solid;
        font-size: 1.2em;
        cursor: pointer;
        @media (max-width: 768px) {
          clear: both;
          margin: 0;
        }
        &:hover {
          border-bottom: 2px solid;
          margin-bottom: -1px;
        }

        a {
          color: #2c3e50;
          text-decoration: none;

          &.participa-cta {
            color: white;
            background: #339167;
            padding: 8px 16px;
            border-radius: 4px;
          }

          &.user-icon {
            padding: 8px 13px;
            background: #ccc;
            border-radius: 4px;
          }
        }

        &.participa-cta {
          background: #339167;
        }
      }
    }

    #mobile-menu-icon {
      display: none;
      cursor: pointer;
      @media (max-width: 768px) {
        display: block;
        position: absolute;
        right: 20px;
        font-size: 1.5em;
      }
    }

  }

  #footer{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 130px;
    padding: 20px 0;
    background: #fff;
    border-top: 1px solid #e4e4e4;
    img {
      margin-bottom: 10px;
    }
  }

}

textarea{
  font-family: $mainFont, sans-serif;
  outline: none;
}

input{
  outline: none;
}

input, textarea, select{
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  background-color: #f4f4f4;
  margin-top: 4px;

  &:focus{
    border-color: #42b983;
    box-shadow: 0 0 0 0.2rem #42b98344;
  }
}

a {
  text-decoration: none;
  color: #2c3e50;
  cursor: pointer;
  outline: none;
}

button {
  border-radius: 5px;
  border: 2px solid #999;
  padding: 4px;
  cursor: pointer;
  color: #202020;
  font-family: $mainFont, sans-serif;
  background-color: #bbbbbb;
  outline: none;
  &:hover {
    border: 2px solid #666 !important;
  }

  &.cta {
    background-color: #42b983;
    border: 2px solid #339167;
    color: white;
  }

  &.cta-big {
    background-color: #339167;
    border: 2px solid transparent;
    color: white;
    font-size: 1.3em;
    font-weight: 600;
  }

  &.borderless{
    background-color: transparent;
    border: none;
    &:hover {
      border: none !important;
      outline: 2px solid #999;
    }
  }
}

</style>
