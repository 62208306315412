<template>
  <div>
      <img id="logo" src="/logo.png" :height="height">
  </div>
</template>

<script>
export default {
  name: 'LoadingLogo',
  props: {
    height: {
      type: Number,
      default: 90
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @keyframes bounceAnim {
    0% {
      transform: scale(0.6);
      filter: drop-shadow(0px 0px 5px #aaa);
      }
    100% {
      transform: scale(0.65);
      filter: drop-shadow(0px 10px 10px #bbb);
    }
  }

  #logo {
    display: block;
    animation-name: bounceAnim;
    animation-duration: 0.4s;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    margin: 0 auto;
    margin-top: 4px;
    text-align: center;
    color: #666;
  }
</style>
