import httpClient from './httpClient'

const END_POINT = '/media'

const getLatestMedia = (limit) => httpClient.get(END_POINT, { params: { sort: 'latest', limit: limit } })

const getPopularMedia = (limit) => httpClient.get(END_POINT, { params: { sort: 'popular', limit: limit } })

const getMedia = (mediaId) => httpClient.get(END_POINT + '/' + mediaId)

const getSuggestions = (query) => httpClient.get(END_POINT + '-suggestions', { params: { query: query, limit: 5 } })

const createMedia = (media) => httpClient.post(END_POINT, { media })

export {
  getLatestMedia,
  getPopularMedia,
  getSuggestions,
  getMedia,
  createMedia
}
