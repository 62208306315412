import axios from 'axios'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    error: null
  },

  getters: {
    authenticated (state) {
      return state.authenticated
    },

    user (state) {
      return state.user
    },

    error (state) {
      return state.error
    }
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    },

    RAISE_ERROR (state, value) {
      state.error = value
    },

    DISMISS_ERROR (state) {
      state.error = null
    }
  },

  actions: {
    async signIn ({ dispatch, commit }, credentials) {
      try {
        await axios.get('/sanctum/csrf-cookie')
        await axios.post('/login', credentials)
      } catch (error) {
        return callError(commit, error)
      }

      return dispatch('me')
    },

    async signOut ({ dispatch, commit }) {
      try {
        await axios.post('/logout')
      } catch (error) {
        return callError(commit, error)
      }
      return dispatch('me')
    },

    async register ({ dispatch, commit }, credentials) {
      try {
        await axios.post('/register', credentials)
      } catch (error) {
        return callError(commit, error)
      }

      return dispatch('me')
    },

    async recoverPassword ({ dispatch, commit }, credentials) {
      try {
        await axios.post('/password/email', credentials)
      } catch (error) {
        return callError(commit, error)
      }
    },

    async resetPassword ({ dispatch, commit }, credentials) {
      try {
        await axios.post('/password/reset/' + credentials.token, credentials)
      } catch (error) {
        return callError(commit, error)
      }
    },

    me ({ commit }) {
      return axios.get('/api/user').then((response) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data)
      }).catch(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
    }
  }
}

async function callError (commit, error) {
  commit('RAISE_ERROR', error.response.data)
  await new Promise(resolve => setTimeout(resolve, 3000))
  commit('DISMISS_ERROR')
}
