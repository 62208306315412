<template>
  <transition name="fade">
    <div v-if="error != null" id="error-container">
      <p><i class="fa fa-warning"/> {{ error.message }}</p>
    </div>
  </transition>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ErrorMessage',
  computed: {
    ...mapGetters({
      error: 'auth/error'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#error-container {
  position: fixed;
  top: 93px;
  left: 0;
  width: 100%;
  z-index: 1000000;
  p {
    color: #994444;
    width: 50%;
    max-width: 500px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 25px;
    border: 2px solid;
    padding: 10px 30px;
    box-sizing: border-box;
    text-align: center;
    box-shadow: 0 2px 10px #00000022;
    @media (max-width: 768px) {
      width: 90%;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-200px)
}
</style>
