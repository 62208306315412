<template>
  <div class="route-content">
    <div id="header">
        <div id="header-content">
            <div id="header-info">
                <div v-if="!user.provider_avatar" id="avatar"><i class="fa fa-user"/></div>
                <div v-if="user.provider_avatar" id="avatar" :style="'background-image: url('+user.provider_avatar+')'"></div>
                <h1 v-if="user" class="media-title">{{ user.name }}</h1>
                <div id="providers" v-if="user.providers">
                  <a class="provider-link" target="_blank" v-for="p in user.providers" v-bind:key="p.id" :href="p.profile_url">
                    {{ $t('provider_profile_link', {providerName: p.name} ) }}
                    <i class="fa fa-external-link-alt"/>
                  </a>
                </div>
            </div>
        </div>
    </div>
    <div id="subs">
      <h1 id="subs-title">Subtítols pujats per l'usuari <span style="font-weight: bold">{{user.name}}</span>:</h1>
      <div class="subs-list">
        <SubtitleEntry v-for='s in user.subtitles' v-bind:key='s.id' :subtitle="s" :author="user" />
      </div>
      <LoadingLogo v-if="!user.subtitles" />
    </div>
    <div id="banners" v-if="user.subtitles">
      <Banner id="twitter-banner" title="Segueix-nos a Twitter!" subtitle="Segueix-nos a Twitter per rebre totes les novetats!" cta="Segueix" icon="fab fa-twitter" href="https://twitter.com/sublliure" target="_blank" style="background-color: #42b983" />
      <Banner id="contribute-banner" title="Participa!" subtitle="Anima't a participar traduint o pujant subtítols!" cta="Participa" icon="fas fa-globe-europe" href="/participa" style="background-color: #2c3e50" />
    </div>
  </div>
</template>

<script>

import { getUser } from '@/libs/api/user.api'
import LoadingLogo from '@/components/LoadingLogo'
import SubtitleEntry from '../components/SubtitleEntry.vue'
import Banner from '@/components/Banner'

export default {
  name: 'UserProfile',
  components: {
    SubtitleEntry,
    LoadingLogo,
    Banner
  },
  data: function () {
    return {
      userSlug: this.$route.params.slug,
      user: this.$route.params.media ?? {}
    }
  },
  beforeMount: function () {
    this.fetchUser()
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mediaSlug = to.params.slug
    this.media = to.params.media
    this.fetchUser()
    next()
  },
  methods: {
    fetchUser: async function () {
      const user = await getUser(this.userSlug)
      this.user = user.data
    }
  }
}

</script>

<style scoped lang="scss">
.route-content {
  width: 100%;
  padding-bottom: 0 !important;
  .media-title {
    font-size: 3em;
    @media (max-width: 768px) {
      font-size: 2em;
      margin-top: 10px;
    }
  }

  #header {
    width: 100%;
    background: #f4f4f4;
    background-position: right -200px center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 20px;

    @media (max-width: 768px) {
      background-position: center;
      padding-top: 0px;
    }

    #header-content {
      width: 100%;
      margin: 0 auto;
      display: flex;
      padding: 20px 10%;
      justify-content: center;
      box-sizing: border-box;
      color: #2c3e50;
      @media (max-width: 768px) {
        display: block;
        text-align: center;
        padding: 20px 5px;
      }
      img {
        margin-right: 20px;
        border-radius: 7px;
        height: 138px;
        min-width: 92px;
        @media (max-width: 768px) {
          margin-right: 0;
          height: 108px;
          min-width: 71px;
        }
      }

      #header-info{
        width: 100%;
        position: relative;
        text-align: center;
        #avatar{
          width: 100px;
          height: 100px;
          display: flex;
          background: #ddd;
          border-radius: 52px;
          align-items: center;
          justify-content: center;
          font-size: 2.5em;
          border: 2px solid #2c3e50;
          margin: 0 auto;
          background-repeat: no-repeat;
          background-position: center;
        }
        h1, p{
          text-shadow: 0px 0px 10px #2c3e5025;
        }
        @media (max-width: 768px) {
          h1 {
            font-size: 1.6em;
          }
        }
      }

      #header-actions {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          display: block;
          position: relative;
          margin-top: 8px;
        }
        a{
          color: white;
          border: 1px solid;
          padding: 8px 16px;
          border-radius: 8px;
          @media (max-width: 768px) {
            padding: 2px 6px;
          }
        }

        i{
          margin-left: 6px;
        }

      }

    }
  }

  #subs {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 20px;

    .subs-list{
      border-radius: 4px;
      box-shadow: 0 1px 4px #ccc;
      background: white;
      @media (max-width: 768px) {
        border-radius: 0;
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 0;
      border-bottom: 1px solid #eee;
    }

    i {
      padding-right: 8px;
    }

    #subs-title, .season-title {
      color: #2c3e50;
      font-weight: 300;
      font-size: 1.2em;
      margin: 16px 0 10px 0;
      @media (max-width: 768px) {
        margin-top: 20px;
        padding: 0 10px;
      }
    }
    .season-title{
      font-weight: bold;
    }
  }

  #banners{
    margin-top: 30px;
  }

  #providers{
    margin-top: 8px;
    .provider-link{
      color: white;
      padding: 4px 8px;
      border-radius: 4px;
      background: #9ec068;
    }
  }

}
</style>
