<template>
  <div class="media-collection" v-if="collection.length > 0">
    <router-link :to="{ name: 'SearchResults', query: filter }">
      <h1>{{ this.title }}</h1>
    </router-link>
    <div class="collection-mask">
      <div class="collection-container">
        <router-link class="collection-card" v-for="media in collection" :key="media.id" :to="{ name: 'MediaDetail', params: { slug: media.slug, media: media } }">
          <img v-lazy="posterForMedia(media)" height="225" width="150" />
          <p class="collection-card-title">{{ media.title }}</p>
          <p>{{ media.year }}</p>
        </router-link>
        <router-link class="collection-card last" :to="{ name: 'SearchResults', query: filter }">
          <p><i class="fas fa-search"></i><br/>{{ $t('see_more') }}</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { search } from '@/libs/api/search.api'

export default {
  name: 'MediaCollection',
  props: {
    title: String,
    filter: Object
  },
  data: function () {
    return {
      collection: []
    }
  },
  beforeMount () {
    this.fetchMedia()
  },
  methods: {
    fetchMedia: async function () {
      const response = await search(this.filter)
      this.collection = response.data.data
      this.filter.collection_name = this.title
    },
    posterForMedia: function (media) {
      if (media.poster_url) {
        return 'https://image.tmdb.org/t/p/w220_and_h330_face' + media.poster_url
      }
      return '/poster_placeholder.png'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.media-collection {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 32px;
  @media (max-width: 768px) {
    width: 100%;
  }

  .collection-mask {
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 16px 0;
  }

  .collection-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: max-content;
    padding: 0 4px;
    @media (max-width: 768px) {
      padding: 0 8px;
    }
  }

  h1 {
    text-transform: uppercase;
    font-weight: lighter;
    text-decoration: underline wavy #339167;
    text-decoration-thickness: 2px;
    @media (max-width: 768px) {
      font-size: 1.2em;
      padding: 0 8px;
      text-decoration: underline dotted #339167;
      text-decoration-thickness: 3px;
    }
  }

  .collection-card {
    cursor: pointer;
    min-width: 150px;
    width: 150px;

    .collection-card-title {
      font-weight: bold;
      max-height: 2.8em;
      overflow: hidden;
      text-overflow: ellipsis
    }

    img {
      box-shadow: 0 2px 8px rgba(0,0,0,0.1);
      border-radius: 4px;
      width: 100%;
      min-height: calc(150px * 1.5);
      height: calc(150px * 1.5);
      background: #dbdbdb;
      overflow: hidden;
    }

    &.last {
      i {
        color: #2c3e50bb;
      }
      color: #2c3e50;
      box-shadow: 0 2px 8px rgba(0,0,0,0.1);
      box-sizing: border-box;
      border-radius: 4px;
      border: 4px solid;
      font-size: 1.2em;
      font-weight: bold;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      height: 225px;
    }
  }

}
</style>
