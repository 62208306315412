<template>
  <div id="translator">
    <div id="header" v-if="originalData">
      <input class="title" type="text" v-model="newFileName" />
      <div id="header-actions">
        <button @click="showTranslator = true; $refs.translator.injectDataToIframe()" id="bt-translate"><i class="fas fa-globe"></i>{{ $t("auto_translate") }}</button>
        <button @click="download()" id="bt-download"><i class="fas fa-file-download"></i>{{ $t("download") }}</button>
        <button @click="publish()" id="bt-publish"><i class="fas fa-check"></i>{{ $t('publish') }}</button>
        <button class="cta-big" @click="showsMobileOptions = true" id="bt-more"><i class="fas fa-ellipsis-v"></i></button>
      </div>
    </div>

    <TranslationTable
    :original="originalData"
    :translation="translatedData"
    @update-translation="translatedData = $event; backup()"
    @uploadOriginalFile="showUploadOriginalFile"
    @uploadTranslationFile="showUploadTranslationFile" />

    <Modal v-show="showsMobileOptions" @close="showsMobileOptions = false">
      <div slot="body" id="mobile-options">
        <button @click="showUploadOriginalFile"><i class="fas fa-folder-open"></i> {{ $t("open_original") }}</button>
        <button @click="showUploadTranslationFile"><i class="fas fa-folder-open"></i> {{ $t("open_translation") }}</button>
      </div>
    </Modal>

    <PublishFlow
      v-if="showPublishModal"
      :content="translatedData"
      :originalContent="originalData"
      :fileName="originalFileName"
      :translationFileName="newFileName"
      @published="showPublishModal = false; $router.push('/')"
      @close="showPublishModal = false" />

    <AutoTranslate
      ref="translator"
      v-show="showTranslator"
      :originals="originalData"
      @close="showTranslator = false"
      @on-receive-translations="setTranslations" />

    <input id="translation-file" accept=".srt,.vtt" class="hidden" type="file" @change="readTranslation" />
    <input id="original-file" accept=".srt,.vtt" class="hidden" type="file" @change="readOriginal($event.target.files[0])" />
    <p id="loader" ref="loader">{{ $t('loading') }}</p>
  </div>
</template>

<script>
// @ is an alias to /src

import { parseSubtitleFile } from '@/libs/sublib'
import TranslationTable from '../components/TranslationTable.vue'
import AutoTranslate from '../components/modals/AutoTranslate.vue'
import Modal from '../components/modals/Modal.vue'
import PublishFlow from '../components/modals/PublishFlow.vue'
import { stringify } from 'subtitle'
import { getHtmlElementType } from '@/libs/helpers'

export default {
  name: 'Translator',
  components: {
    AutoTranslate,
    Modal,
    TranslationTable,
    PublishFlow
  },
  data: function () {
    return {
      originalFileName: '',
      newFileName: '',
      originalData: null,
      translatedData: [],
      showTranslator: false,
      showsMobileOptions: false,
      showPublishModal: false,
      originalFile: this.$route.params.file
    }
  },
  methods: {
    readOriginal: async function (file) {
      this.originalData = []
      this.translatedData = []
      const parseResult = await parseSubtitleFile(file)
      if (parseResult.parsed === true) {
        this.originalData = Object.freeze(parseResult.data)
        this.loadSavedData(file.name)
        this.$refs.loader.style.display = 'none'
        this.originalFileName = file.name
        this.newFileName = file.name
      } else {
        alert('No s\'ha pogut llegir el fitxer.')
      }
    },
    readTranslation: async function (evt) {
      const file = evt.target.files[0]
      this.translatedData = []
      const parseResult = await parseSubtitleFile(file)
      if (parseResult.parsed === true) {
        this.translatedData = parseResult.data.map(function (item) { return { text: item.text, edited: false } })
      } else {
        alert('No s\'ha pogut llegir el fitxer.')
      }
    },
    setTranslations: function (data) {
      this.showTranslator = false
      console.log(data.shouldMantainFormattingTags)
      if (data.shouldMantainFormattingTags && this.originalData != null) {
        const originalData = this.originalData
        this.translatedData = data.translations.map(function (translation, index) {
          const original = originalData[index].text
          if (original !== null) {
            const htmlTag = getHtmlElementType(original)
            if (htmlTag !== null) {
              const tag = htmlTag.toLowerCase()
              return { text: '<' + tag + '>' + translation + '</' + tag + '>', edited: false }
            } else {
              return { text: translation, edited: false }
            }
          }
        })
      } else {
        this.translatedData = data.translations.map(function (item) { return { text: item, edited: false } })
      }
    },
    loadSavedData: function (fileName) {
      if (localStorage[fileName] && localStorage[fileName].length > 0) {
        this.translatedData = JSON.parse(localStorage[fileName])
      } else {
        this.translatedData = this.originalData.map(function () { return { text: '', edited: false } })
      }
    },
    publish: function () {
      const translation = this.translatedData
      this.translatedData = this.originalData.map(function (item, index) {
        return { start: item.start, end: item.end, text: translation[index].text }
      })
      this.showPublishModal = true
    },
    download: function () {
      const self = this
      this.translatedData = JSON.parse(JSON.stringify(this.translatedData))
      const translated = this.originalData.map(function (item, index) {
        return { start: item.start, end: item.end, text: self.translatedData[index].text }
      })
      const text = stringify(translated)
      var element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
      element.setAttribute('download', this.generateFileName())
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
    showUploadTranslationFile: function () {
      this.showsMobileOptions = false
      document.getElementById('translation-file').click()
    },
    showUploadOriginalFile: function () {
      this.showsMobileOptions = false
      document.getElementById('original-file').click()
    },
    generateFileName: function () {
      if (this.newFileName !== undefined && this.newFileName !== null && this.newFileName.length > 0) {
        if (this.newFileName.endsWith('.srt') || this.newFileName.endsWith('.vtt')) {
          return this.newFileName
        } else {
          return this.newFileName + '.srt'
        }
      }
      return 'translation.srt'
    },
    backup: function () {
      if (this.translatedData.length > 0 && this.originalFileName.length > 0) {
        console.log('updated translation')
        localStorage[this.originalFileName] = JSON.stringify(this.translatedData)
      }
    }
  },
  mounted: function () {
    if (this.$route.params.file) {
      this.readOriginal(this.$route.params.file)
    } else {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss">
#translator{
  padding: 14px 1% 0 1%;
  background-color: white;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.hidden {
  display: none;
}

#loader {
  text-align: center;
  font-size: 1.5em;
  padding: 18% 0;
}

#header {
  display: flex;
  padding-bottom: 24px;
  flex: 0;

  .title {
    flex: 1;
    font-size: 2em;
    font-family: 'Manrope', sans-serif;
    font-weight: 500;
    color: black;
    background-color: #efefef;
    border: none;
    border-bottom: 3px solid #aaa;
    margin-right: 8px;
    text-overflow: ellipsis;
  }

  #header-actions {
    display: flex;
    button {
      margin: 4px 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100px;
    }

    #bt-publish, #bt-download {
      background: #339167;
      color: white;
      border-color: #2e835d;
    }

    #bt-more{
      display: none;
    }

  }

}

#bt-translate {
  animation-name: bounceAnim;
  animation-duration: 0.4s;
  animation-direction: alternate-reverse;
  animation-iteration-count: 8;
  background-color: #ff9900;
  border-color: #d47f00;
}

@keyframes bounceAnim {
  0% { transform: scale(0.95); background-color: #ffd698; }
  100% { transform: scale(1); background-color: #ff9900; }
}

@media (max-width: 768px) {
  .route-content{
    padding-top: 10px;
    background: white;
  }

  #header {
    display: block;
    padding-bottom: 10px;
    width: 100%;
    box-shadow: 0 5px 10px #ccc;

    .title {
      width: 100%;
      margin: 0 0 12px 0;
    }

    #header-actions {
      justify-content: center;
    }

  }

  #bt-more {
    display: flex !important;
    color: #666;
    border: 2px solid #666;
    background-color: white;
  }

  #mobile-options {
    button {
      margin: 0;
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 5px;
      font-size: 1.5em;
    }
  }
}

</style>
