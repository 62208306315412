<template>
  <div class="subtitle-entry" v-if="!isDeleted">
    <div class="subtitle-info">
      <p class="filename" :title="$t('pujat', { data: uploadDate })"><i class="fas fa-file fa-fw"></i> {{ updatedSubtitle.original_file_name }}</p>
      <p v-if="showsUser"><i class="fas fa-user fa-fw"></i>
        <span v-if="subtitleAuthor"><router-link :to="'/user/'+subtitleAuthor.slug" >{{ subtitleAuthor.name }}</router-link></span>
      </p>
      <div class="comment-container" v-if="updatedSubtitle.comment">
        <i class="fas fa-comment fa-fw"></i>
        <p class="comment" v-html="linkifyComment(updatedSubtitle.comment)"></p>
      </div>
      <p v-if="updatedSubtitle.quality === 4" :title="$t('quality_top')"><span class="dot"></span><span class="dot"></span><span class="dot"></span><i class="fas fa-medal" style="color: #090; font-size: 0.6em"></i></p>
      <p v-if="updatedSubtitle.quality === 3" :title="$t('quality_good')"><span class="dot"></span><span class="dot"></span><span class="dot"></span></p>
      <p v-if="updatedSubtitle.quality === 2" :title="$t('quality_regular')"><span class="dot orange"></span><span class="dot orange"></span><span class="dot gray"></span></p>
      <p v-if="updatedSubtitle.quality === 1" :title="$t('quality_bad')"><span class="dot red"></span><span class="dot gray"></span><span class="dot gray"></span></p>

      <div class="preview-container" v-if="showPreview">
        <textarea class="preview-text" v-model="subtitlePreview"></textarea >
      </div>

    </div>
    <div class="actions">
      <div class="action preview" @click="showPreview = !showPreview; fetchPreview()" v-if="userIsAdmin"><i class="fas fa-glasses fa-fw"></i><span class="action-title">{{ $t('preview') }}</span></div>
      <div class="action edit" @click="isEditing = true" v-if="userOwnsSubtitle || userIsAdmin"><i class="fas fa-pencil-alt fa-fw"></i><span class="action-title">{{ $t('edit') }}</span></div>
      <div class="action download" @click="download(subtitle)"><i :title="$t('download')" :class="'fas fa-' + (updatedSubtitle.is_external === false ? 'arrow-down' : 'external-link-alt')"></i><span class="action-title" :title="'Descarregat ' + updatedSubtitle.download_count + ' vegades'">{{ $t('download') }}</span></div>
    </div>
    <EditSubtitle
        v-show="isEditing && !userIsAdmin"
        :subtitle="Object.assign({}, updatedSubtitle)"
        :mediaType="mediaType"
        @close="isEditing = false"
        @updated="updatedSubtitle = $event; isEditing = false"
        @deleted="isDeleted = true; isEditing = false">
    </EditSubtitle>
    <Modal v-if="isEditing && userIsAdmin" @close="isEditing = false">
      <div slot="body">
        <iframe id="admin-frame" :src="'/admin/subtitle/'+updatedSubtitle.id" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { previewSubtitle, getFileData } from '@/libs/api/subtitle.api'
import { mapGetters } from 'vuex'
import EditSubtitle from '@/components/modals/EditSubtitle'
import Modal from '@/components/modals/Modal'

export default {
  name: 'SubtitleEntry',
  components: {
    EditSubtitle,
    Modal
  },
  props: {
    subtitle: Object,
    author: Object,
    showsUser: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {
      subtitlePreview: '',
      updatedSubtitle: this.subtitle,
      showPreview: false,
      isEditing: false,
      isDeleted: false
    }
  },
  methods: {
    linkifyComment: function (comment) {
      var urlRegex = /(https?:\/\/[^\s]+)/g
      return comment.replace(urlRegex, '<a style="color:#339167;text-decoration:underline" href="$1" target="_blank">$1</a>')
    },
    download: async function (subtitle) {
      if (this.updatedSubtitle.is_external === true) {
        window.open(this.updatedSubtitle.file_ref, '_blank')
        return
      }
      const encodedData = await getFileData(this.updatedSubtitle.file_ref)
      var element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodedData)
      element.setAttribute('download', this.updatedSubtitle.translation_file_name ?? this.updatedSubtitle.original_file_name ?? 'subs.srt')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
    fetchPreview: async function () {
      if (this.subtitlePreview.length > 0) { return }
      const previewItems = await previewSubtitle(this.updatedSubtitle.id)
      this.subtitlePreview = previewItems.data.join('\n\n')
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    subtitleAuthor: function () {
      if (this.subtitle.user !== null && this.subtitle.user !== undefined) {
        return this.subtitle.user
      } else if (this.author !== null && this.author !== undefined) {
        return this.author
      } else if (this.subtitle.external_user && this.subtitle.external_user.length > 0) {
        return { name: this.subtitle.external_user, slug: this.subtitle.external_user }
      } else {
        return { name: 'anon', slug: 'anon' }
      }
    },
    userIsAdmin: function () {
      return this.user && this.user.name === 'subbot'
    },
    userOwnsSubtitle: function () {
      return this.user && this.subtitleAuthor !== undefined && this.user.id === this.subtitleAuthor.id
    },
    mediaType: function () {
      if (this.updatedSubtitle.media !== null && this.updatedSubtitle.media !== undefined) {
        return this.updatedSubtitle.media.type
      } else {
        return this.updatedSubtitle.episode !== null && this.updatedSubtitle.episode > 0 && this.updatedSubtitle.season !== null && this.updatedSubtitle.season > 0 ? 'tv' : 'movie'
      }
    },
    uploadDate: function () {
      const date = new Date(this.subtitle.updated_at)
      return date.toLocaleString()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.subtitle-entry {
  display: flex;
  flex-direction: row;
  padding: 16px 8px 16px 16px;
  box-sizing: border-box;
  border-top: 1px solid #eee;
  align-items: center;

  .filename{
    font-weight: bold;
  }

  .subtitle-info {
    flex: 1;
    min-width: 0;

    p {
      white-space: nowrap;
      padding-right: 10px;
      overflow: auto;
    }

    .comment-container {
      position: relative;
      .fa-comment{
        position: relative;
        z-index: 10000;
      }
      .comment{
        white-space: normal;
        background: #eee;
        margin: -13px 12px 3px 8px;
        padding: 8px;
        border-radius: 8px;
      }
    }

  }

  .actions {
    display: flex;
    flex-direction: row;
  }

  .action {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-left: 1px solid #eee;
    text-align: center;
    i{
      font-size: 2em;
      padding: 10px 20px;
      @media (max-width: 768px) {
        font-size: 1.5em;
        padding: 10px;
      }
    }
    .download-count{
      text-align: center;
      color: #999;
    }

    .action-title{
      padding-top: 3px;
      font-size: 0.8em;
      text-transform: uppercase;
      color: #999;
    }
  }

  .preview-button {
    border: 2px solid #ddd;
    background: #eee;
  }

  .preview-text{
    min-width: 90%;
    min-height: 200px;
  }

}

.dot {
  height: 6px;
  width: 6px;
  background-color: #090;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px;

  &.red {
    background-color: #900;
  }
  &.gray {
    background-color: #ccc;
  }
  &.orange {
    background-color: #FB0;
  }
}

#admin-frame{
  width:100%;
  height:300px;
  border:none;
}
</style>
