<template>
  <div class='route-content'>
    <div v-if="filters.collection_name">
      <h1 id="collection">
        <span class="collection-label">{{ $t('collection') }}: </span>
        <span class="collection-name">{{ filters.collection_name }}</span>
      </h1>
    </div>
    <div id="filters" v-if="!filters.collection_name">
      <div id="filter-title">
        <p><i class="fas fa-sliders-h"></i> {{ $t('filters') }}</p>
      </div>
      <div id="filter-options">
        <div class="select">
          <span v-if="newFilters.type === undefined || newFilters.type === null" class="placeholder">{{ $t('type') }}</span>
          <select name="type" v-model="newFilters.type">
            <option value="" selected>{{ $t('all_types') }}</option>
            <option value="movie">{{ $t('movie') }}</option>
            <option value="tv">{{ $t('tv') }}</option>
            <option value="other">{{ $t('other') }}</option>
          </select>
        </div>
        <div class="select">
          <span v-if="newFilters.original_language === undefined || newFilters.original_language === null" class="placeholder">{{ $t('original_language') }}</span>
          <select name="original_language" v-model="newFilters.original_language">
            <option value="" selected>{{ $t('all_languages') }}</option>
            <option v-for="l in languages" :key="l" :value="l">{{ isoLangs[l].name }}</option>
          </select>
        </div>
        <input type="number" name="year" pattern="[0-9]{10}" min="0" step="1" v-model="newFilters.year" :placeholder="$t('year')" />
      </div>
      <div id="filter-cta">
        <router-link v-on:click.native="newFilters = { type: '', original_language: '' }" class="clear" to="/search">{{ $t('clear') }}</router-link>
        <router-link :to="{ path: '/search', query: newFilters }">{{ $t('apply') }}</router-link>
      </div>
    </div>
    <div id="lists" v-if="results.data.length > 0">
      <div class="results">
        <h1><i class="fas fa-search"/>{{ results.total + ' ' + $t('results') }}</h1>
        <SearchResult v-for='m in results.data' :key="m.id" :media="m" />
      </div>
    </div>
    <div class="pagination" v-if="results.last_page > results.current_page || results.current_page !== 1">
      <router-link v-for="n in results.last_page" :key="n" :to="{ path: '/search', query: filtersForPage(n) }" :class="results.current_page === n ? 'selected' : ''">{{ n }}</router-link>
    </div>
    <LoadingLogo v-if="isLoading" style="padding-top: 100px" />
    <p class="no-results" v-if="results.data.length === 0 && !isLoading"><i class="fas fa-heart-broken"></i><br/>{{ $t('no_results') }}</p>
  </div>
</template>

<script>
import SearchResult from '../components/SearchResult.vue'
import { search, getLanguages } from '@/libs/api/search.api'
import LoadingLogo from '@/components/LoadingLogo'
import { isoLangs } from '@/libs/iso-langs'

export default {
  name: 'SearchResults',
  components: {
    SearchResult,
    LoadingLogo
  },
  data: function () {
    return {
      filters: JSON.parse(JSON.stringify(this.$route.query)),
      newFilters: JSON.parse(JSON.stringify(this.$route.query)),
      results: {
        data: []
      },
      languages: [],
      isoLangs: isoLangs,
      isLoading: false
    }
  },
  beforeMount () {
    this.performSearch()
    this.getLanguages()
  },
  beforeRouteUpdate: function (to, from, next) {
    this.filters = to.query
    this.performSearch()
    next()
  },
  methods: {
    performSearch: async function () {
      this.isLoading = true
      this.results = {
        data: []
      }
      const res = await search(this.filters)
      this.results = res.data
      this.isLoading = false
    },
    getLanguages: async function () {
      const res = await getLanguages()
      this.languages = res.data
    },
    filtersForPage: function (page) {
      var filters = JSON.parse(JSON.stringify(this.filters))
      filters.page = page
      return filters
    }
  }
}

</script>

<style scoped lang='scss'>
.route-content {
  width: 80%;
  margin: 0 auto;
  @media (max-width: 768px) {
    width: 95%;
  }

  h1 {
    font-weight: 300;
    font-size: 1.2em;
    text-transform: uppercase;
    margin: 30px 0 10px 0;
    @media (max-width: 768px) {
      margin-top: 20px;
    }

    i {
      padding-right: 8px;
    }
  }

  ul li {
    cursor: pointer
  }

  .pagination {
    text-align: center;
    margin-top: 20px;
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      justify-content: start;
    }
    a {
      padding: 10px;
      background: #2c3e50;
      color: white;
      margin-right: 2px;
      display: block;
      &.selected {
        background: #339167;
        font-weight: bold;
      }
    }
  }

  .collection-label{
    text-transform: uppercase;
    font-weight: bold;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .collection-name{
    text-transform: uppercase;
    font-weight: lighter;
    text-decoration: underline wavy #339167;
    text-decoration-thickness: 2px;
  }

  #filters{
    display: flex;
    align-items: center;
    padding: 16px;
    margin-top: 20px;
    background: #42b983;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    border-radius: 4px;
    box-sizing: border-box;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: start;
    }

    #filter-title {
      text-transform: uppercase;
      font-weight: bold;
      p {
        padding: 0 16px;
      }
      @media (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
    }

    #filter-options {
      flex: 1;
      text-align: center;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        text-align: start;
        width: 100%;
        padding: 16px 0;
      }
      label {
        text-transform: uppercase;
        margin-right: 8px;
      }
      .select{
        display: inline;
        position: relative;
        .placeholder{
          position: absolute;
          text-align: center;
          width: calc(100% - 8px);
          top: 1px;
          user-select: none;
          pointer-events: none;
          font-size: 0.9em;
          font-weight: bold;
          color: rgba(255,255,255,0.8);
          text-shadow: 0 2px 1px rgba(0,0,0,0.2);
          @media (max-width: 768px) {
            top: 11px;
          }
        }
      }
      input, select{
        margin: 0 8px 0 0;
        width: 29%;
        max-width: 160px;
        background: rgba(255,255,255,0.2);
        border: none;
        appearance: none;
        text-align: center;
        text-align-last: center;
        -moz-text-align-last: center;
        box-sizing: border-box;
        color: white;
        text-shadow: 0 2px 1px rgba(0,0,0,0.2);
        font-weight: bold;
        outline: none;
        @media (max-width: 768px) {
          width: 100%;
          max-width: 100%;
          margin: 4px 0 0 0;
        }
        &::placeholder{
          color: rgba(255,255,255,0.8);
        }
      }
      input[type=number] {
        -moz-appearance: textfield;
      }
    }

    #filter-cta {
      a {
        width: 100%;
        height: 100%;
        background: none;
        color: white;
        font-weight: bold;
        border: none;
        text-transform: uppercase;
        padding: 0 16px;
        &.clear{
          opacity: 0.6;
        }
      }
      @media (max-width: 768px) {
        width: 100%;
        text-align: center;
      }
    }
  }

  .no-results{
    padding: 80px 0;
    text-align: center;
    i {
      font-size: 4em;
      margin-bottom: 20px;
    }
  }

}
</style>
