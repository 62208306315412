<template>
  <div>
    <Modal @close="$emit('close')">
      <div slot="header" v-if="!showLoading">
        <h1>{{ $t('media_info') }}</h1>
        <p v-if="!editMode">{{ $t('media_info_help') }}</p>
      </div>
      <div slot="body" @click="mediaResults = []">
        <p class="filename"><i class="fas fa-file fa-fw"></i> {{ originalFileName }}</p>
        <h1 v-if="showLoading && loadingText.length > 0" style="text-align: center">{{ loadingText }}</h1>
        <SearchResult v-if="!editMode && !showLoading" :media="mediaInfo" :linkEnabled="false" :imgHeight="'117px'" :imgWidth="'78px'">
          <p slot="extras" v-if="mediaInfo.season && mediaInfo.episode">{{ $t('season') }} {{ mediaInfo.season }}, {{ $t('episode') }} {{ mediaInfo.episode }}</p>
        </SearchResult>
        <LoadingLogo v-if="showLoading" />
        <form v-if="editMode && !showLoading" style="margin-bottom: 16px">
          <label v-if="selectedMediaResult == null">{{ $t('title') }}</label>
          <input
            type="text"
            v-if="selectedMediaResult == null"
            v-model="mediaSearchQuery"
            @input="isSearchingMedia = mediaSearchQuery.length > 0"
            @focus="mediaSearchFocused = true"
            @blur="mediaSearchFocused = false"
            required
          />
          <div class="media-results" v-if="selectedMediaResult == null && (isSearchingMedia || (mediaResults.length > 0 && mediaSearchQuery !== ''))">
            <div>
              <SearchResult
                v-for="media in mediaResults"
                :key="media.id"
                :media="media"
                :linkEnabled="false"
                v-on:click.native="selectMedia(media)"
                :styling="'minimal'"
              />
            </div>
            <LoadingLogo v-if="isSearchingMedia && mediaResults.length == 0" :height="40" style="padding: 10px 0; box-shadow: 0 0 4px #ccc" />
          </div>
          <SearchResult
            v-if="selectedMediaResult"
            :media="selectedMediaResult"
            :linkEnabled="false"
            :imgHeight="'117px'"
            :imgWidth="'78px'"
          >
            <p slot="extras" class="edit-selected-media" v-if="selectedMediaResult" @click="selectedMediaResult = null">
              <i class="fas fa-pencil-alt"></i>
            </p>
          </SearchResult>
          <label v-if="selectedMediaResult == null">{{ $t('year') }}</label>
          <input v-if="selectedMediaResult == null" type="number" v-model="mediaInfo.year" style="margin-bottom: 10px;" />
          <label v-if="selectedMediaResult == null">{{ $t('type') }}</label>
          <div class="input-group-type">
            <select v-model="mediaInfo.type" v-if="selectedMediaResult == null">
              <option value="movie">{{ $t('movie') }}</option>
              <option value="tv">{{ $t('tv') }}</option>
              <option value="other">{{ $t('other') }}</option>
            </select>
            <p v-if="mediaInfo.type === 'tv'">{{ $t('season') }}</p>
            <input v-if="mediaInfo.type === 'tv'" type="number" v-model="mediaInfo.season" min="0" />
            <p v-if="mediaInfo.type === 'tv'">{{ $t('episode') }}</p>
            <input v-if="mediaInfo.type === 'tv'" type="number" v-model="mediaInfo.episode" min="0" />
          </div>
        </form>
        <textarea v-if="!showLoading" v-model="mediaInfo.comment" :placeholder="$t('add_comment')" maxlength="200" />
      </div>
      <div slot="footer" class="buttons" v-if="!showLoading">
        <button class="cta-big" @click="editMode = true; mediaInfo.tmdb_id = null; mediaInfo.poster_url = null; mediaInfo.backdrop_url = null; mediaInfo.title = parsedInfo.title" v-if="!editMode" style="background: gray">{{ $t('no') }}</button>
        <button class="cta-big" @click="publishSubtitle(mediaInfo)" :disabled="showLoading">{{ editMode ? $t('publish') : $t('yes_publish') }}</button>
      </div>
    </Modal>
    <Modal @close="$emit('close')" v-if="!authenticated && showAuthModal">
      <div slot="body">
        <h1>Vols registrar-te?</h1>
        <p>Tens l'opció de registrar-te o també la de publicar de forma anònima.</p>
        <Login id="login"/>
        <p style="text-align: center; margin-top: 20px">
          <a class="anon-publish link" @click="publishAnonymously=true;publishSubtitle(mediaInfo);showAuthModal=false">Publica de forma anònima</a>
        </p>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '../modals/Modal.vue'
import LoadingLogo from '../LoadingLogo.vue'
import SearchResult from '../SearchResult.vue'
import Login from '@/components/Login.vue'
import parseFileName from 'parse-torrent-name'
import { parseSubtitleFile } from '@/libs/sublib'
import { searchMovie, searchTV } from '../../libs/tmdb.js'
import { detectLanguage } from '@/libs/api/language.api'
import { createSubtitle, createRawSubtitle } from '@/libs/api/subtitle.api'
import { getSuggestions } from '@/libs/api/media.api'
import { mapGetters } from 'vuex'
import { debounce } from '@/libs/helpers'

export default {
  name: 'PublishFlow',
  components: {
    Modal,
    SearchResult,
    LoadingLogo,
    Login
  },
  props: {
    file: File,
    content: Array,
    originalContent: Array,
    fileName: String,
    translationFileName: String
  },
  data: function () {
    return {
      searchResults: [],
      mediaInfo: {},
      editMode: false,
      showAuthModal: false,
      isPublishing: false,
      isLoading: false,
      loadingText: this.$t('loading'),
      publishAnonymously: false,
      fileContent: {},
      isParsedFile: false,
      mediaSearchQuery: '',
      mediaResults: [],
      selectedMediaResult: null,
      isSearchingMedia: false,
      mediaSearchFocused: false
    }
  },
  methods: {
    buildMediaInfo: function () {
      this.mediaInfo = {
        title: this.parsedTitle,
        type: this.parsedInfo.episode ? 'tv' : 'movie',
        year: this.releaseYear,
        release_date: this.releaseDate
      }
      this.mediaSearchQuery = this.parsedTitle
      if (this.mediaInfo.type === 'tv') {
        this.mediaInfo.season = this.parsedInfo.season ?? 1
        this.mediaInfo.episode = this.parsedInfo.episode
      }
      if (this.searchResults && this.searchResults[0]) {
        const topMatch = this.searchResults[0]
        this.mediaInfo.tmdb_id = topMatch.id
        this.mediaInfo.poster_url = topMatch.poster_path
        this.mediaInfo.backdrop_url = topMatch.backdrop_path
        this.mediaInfo.original_language = topMatch.original_language
        this.mediaInfo.original_title = this.originalTitleForMedia(topMatch)
        this.mediaInfo.genre_ids = topMatch.genre_ids
      }
    },
    publishSubtitle: async function (mediaInfo) {
      if (!this.authenticated && !this.publishAnonymously) {
        this.showAuthModal = true
        return
      }
      if (this.editMode) {
        mediaInfo.title = this.mediaSearchQuery
      }
      mediaInfo.original_file_name = this.originalFileName
      mediaInfo.translation_file_name = this.translationFileName
      mediaInfo.year = mediaInfo.year == null ? 2020 : mediaInfo.year
      this.loadingText = this.$t('publishing')
      this.isPublishing = true
      if (this.isParsedFile) {
        const detectedLanguages = await this.getLanguages(this.fileContent)
        mediaInfo.detected_language = detectedLanguages.length > 0 ? detectedLanguages[0] : null
        const isInCatalan = detectedLanguages.length > 0 && detectedLanguages.includes('ca')
        var shouldLaunchTranslator = false
        if (!isInCatalan) {
          shouldLaunchTranslator = confirm('Sembla que aquest contingut no està en català. Vols traduir-lo?')
          if (shouldLaunchTranslator) {
            this.$emit('launchTranslator', this.file)
          }
        }
      }
      if (!shouldLaunchTranslator) {
        try {
          if (this.isParsedFile) {
            await createSubtitle(mediaInfo, this.fileContent, this.originalContent)
          } else {
            await createRawSubtitle(mediaInfo, this.file)
          }
          this.$emit('published')
        } catch (err) {
          alert(err)
        }
      }
      this.isPublishing = false
      this.savedMediaInfo = {}
      this.publishAnonymously = false
    },
    getLanguages: async function (content) {
      if (this.isParsedFile === false) { return [] }
      const sample = this.fileContent.slice(0, 6).map((item) => { return item.text }).join(' ') // Agafa les 4 primeres frases
      if (sample.length > 0) {
        const result = await detectLanguage(sample)
        return result.data.map((r) => { return r.language })
      }
      return []
    },
    searchMedia: async function () {
      if (this.mediaSearchQuery.trim().length < 2 || !this.mediaSearchFocused) {
        return
      }
      this.isSearchingMedia = true
      const results = await getSuggestions(this.mediaSearchQuery)
      this.mediaResults = results.data.data.slice(0, 5)
      this.isSearchingMedia = false
    },
    selectMedia: function (media) {
      this.mediaSearchQuery = media.title
      this.selectedMediaResult = media
      this.mediaInfo.tmdb_id = media.tmdb_id
      this.mediaInfo.type = media.type
      this.mediaInfo.year = media.year
      this.mediaInfo.backdrop_url = media.backdrop_url
      this.mediaInfo.poster_url = media.poster_url
      this.mediaInfo.original_title = this.originalTitleForMedia(media)
      this.mediaInfo.original_language = media.original_language
      this.mediaInfo.genre_ids = media.genres
      this.mediaResults = []
    },
    originalTitleForMedia: function (media) {
      const originalName = media.original_name
      const originalTitle = media.original_title
      return originalName || originalTitle
    }
  },
  computed: {
    releaseDate: function () {
      if (this.searchResults && this.searchResults.length > 0) {
        if (this.searchResults[0].release_date) {
          return this.searchResults[0].release_date
        } else if (this.searchResults[0].first_air_date) {
          return this.searchResults[0].first_air_date
        }
      }
      return null
    },
    releaseYear: function () {
      if (this.releaseDate && this.releaseDate.length > 0) {
        return this.releaseDate.split('-')[0]
      }
      return null
    },
    parsedTitle: function () {
      if (this.searchResults && this.searchResults[0]) {
        const name = this.searchResults[0].name
        const title = this.searchResults[0].title
        return name || title
      }
      return this.parsedInfo.title
    },
    mediaTitle: function () {
      return this.mediaInfo.title
    },
    mediaType: function () {
      return this.mediaInfo.type
    },
    originalFileName: function () {
      return this.file ? this.file.name : this.fileName
    },
    showLoading: function () {
      return this.isLoading || this.isPublishing
    },
    parsedInfo: function () {
      const filename = this.originalFileName.replace(/\[/g, '').replace(/\]/g, '.')
      return parseFileName(filename)
    },
    ...mapGetters({
      authenticated: 'auth/authenticated'
    })
  },
  watch: {
    mediaType: function (newVal, oldVal) {
      if (oldVal === undefined) {
        return
      }
      if (newVal !== 'tv') {
        delete this.mediaInfo.episode
        delete this.mediaInfo.season
      }
      if (this.selectedMediaResult == null) {
        this.mediaInfo.tmdb_id = null
      }
    },
    mediaTitle: function (newVal, oldVal) {
      if (oldVal === undefined) {
        return
      }
      if (this.selectedMediaResult == null) {
        this.mediaInfo.tmdb_id = null
      }
    },
    mediaSearchQuery: debounce(function (newVal) {
      this.searchMedia()
    }, 500)
  },
  created: async function () {
    if (this.file && this.file.size > 1000000) {
      alert('Aquest fitxer és massa gran.')
      this.isLoading = false
      this.$emit('close')
      return
    }
    const mediaTitle = this.parsedInfo.title
    if (mediaTitle.endsWith('-')) {
      this.parsedInfo.title = mediaTitle.substring(0, mediaTitle.length - 1)
    }
    // If it has episode info we search for TV if not we search for movies
    this.isLoading = true
    if (this.parsedInfo.episode) {
      this.searchResults = await searchTV(this.parsedInfo.title)
    } else {
      this.searchResults = await searchMovie(this.parsedInfo.title, this.parsedInfo.year !== undefined ? this.parsedInfo.year : null)
    }
    this.buildMediaInfo()
    if (this.file) {
      const parseResult = await parseSubtitleFile(this.file)
      if (parseResult.parsed === true) {
        this.isParsedFile = true
        this.fileContent = parseResult.data
      }
    } else {
      this.fileContent = this.content
      this.isParsedFile = true
    }
    this.isLoading = false
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.buttons{
  display:flex;
  flex-direction: row;
  gap: 5px;
  button {
    flex: 1;
  }
}

label, input, button, textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

textarea {
  max-width: 100%;
  min-width: 100%;
  max-height: 60px;
  padding: 8px;
  box-sizing: border-box;
  resize: none;
}

.input-group-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  input[type=number] {
    width: 80px;
  }
}

#login {
    margin-top: 24px;
    border: 1px solid #2c3e5044;
    border-radius: 4px;
    padding: 20px;
  }

.anon-publish{
  background: transparent;
  font-size: 1.3em;
  text-align: center;
}

.media-results{
  position: absolute;
  z-index: 10000;
  width: 100%;
  background: white;
  left: 0;
  padding: 0 31px;
  box-sizing: border-box;
}

.filename{
  padding: 8px 4px;
  margin-bottom: 8px;
  box-sizing: border-box;
  background: #e9f4ed;
  border-radius: 8px;
  word-wrap: anywhere;
}

.edit-selected-media{
  position: absolute;
  top: 4px;
  right: 8px;
  cursor: pointer;
}

</style>
