import axios from 'axios'

const BASE_URL = 'https://api.themoviedb.org/3'
const API_KEY = 'a4571caf3b2611c1effc82a2c8323b15'

const httpClient = axios.create({
  baseURL: BASE_URL,
  timeout: 20000, // 20 secs
  headers: {
    'Content-Type': 'application/json'
  }
})

const searchMovie = async (title, year) => {
  return await searchMedia(title, 'movie', year)
}

const searchTV = async (title) => {
  return await searchMedia(title, 'tv')
}

const searchAll = async (title) => {
  return await searchMedia(title, 'multi')
}

const searchMedia = async (title, type, year) => {
  if (title.length === 0) {
    return
  }
  var components = title.split(' ')
  const response = await httpClient.get('/search/' + type, { params: { api_key: API_KEY, query: components.join(' '), primary_release_year: year } })
  const results = response.data.results
  if (results.length === 0) {
    components.pop()
    return searchMedia(components.join(' '), type)
  }
  return results.sort((a, b) => b.popularity > a.popularity ? 1 : -1)
}

const mediaDetails = async (type, tmdbId) => {
  const response = await httpClient.get('/search/' + type + '/' + tmdbId, { params: { api_key: API_KEY } })
  const results = response.data.results
  return results
}

export {
  searchMovie,
  searchTV,
  searchAll,
  mediaDetails
}
