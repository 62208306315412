import Vue from 'vue'
import VueRouter from 'vue-router'
import Translator from '../views/Translator.vue'
import SearchResults from '../views/SearchResults.vue'
import Home from '../views/Home.vue'
import MediaDetail from '../views/MediaDetail.vue'
import UserProfile from '../views/UserProfile.vue'
import Participa from '../views/Participa.vue'
import LoginView from '../views/LoginView.vue'
import PasswordReset from '../views/PasswordReset.vue'
import About from '../views/About.vue'
import NotFoundComponent from '../views/NotFoundComponent.vue'
import Terms from '../views/Terms.vue'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Home
  },
  {
    path: '/translator',
    name: 'Translator',
    component: Translator
  },
  {
    path: '/subtitles',
    name: 'Home',
    component: Home
  },
  {
    path: '/media/:slug',
    name: 'MediaDetail',
    component: MediaDetail
  },
  {
    path: '/user/:slug',
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: '/search',
    name: 'SearchResults',
    component: SearchResults
  },
  {
    path: '/participa',
    name: 'Participa',
    component: Participa
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/password/reset/:token',
    name: 'PasswordReset',
    component: PasswordReset
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  { path: '*', component: NotFoundComponent }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
