<template>
  <div>
    <ErrorMessage />
    <LoadingLogo v-if="loading"/>
    <a v-if="!authenticated && !loading" :class="{tab: true, selected: mode==='register'}" @click="mode='register'">{{ $t('sign_in') }}</a>
    <a v-if="!authenticated && !loading" :class="{tab: true, selected: mode==='login'}" @click="mode='login'">{{ $t('login') }}</a>
    <form v-if="!authenticated && mode === 'login' && !loading" action="#" @submit.prevent="doLogIn">
      <div>
        <label for="email">{{ $t('email') }}</label>
        <input type="text" name="email" id="email" v-model="loginForm.email" required>
      </div>
      <div>
        <label for="password">{{ $t('password') }}</label>
        <input type="password" name="password" id="password" v-model="loginForm.password" required>
      </div>
      <div>
        <button type="submit" class="cta-big">
          {{ $t('login') }}
        </button>
      </div>
      <label style="text-align:center"><a @click="mode='reset';resetForm.email = loginForm.email"  class="link">{{ $t('forgot_password') }}</a></label>
    </form>
    <form v-if="!authenticated && mode === 'register' && !loading" action="#" @submit.prevent="doRegister">
      <div>
        <label for="name">{{ $t('username') }}</label>
        <input type="text" name="name" id="name" v-model="registerForm.name" required>
      </div>
      <div>
        <label for="email">{{ $t('email') }}</label>
        <input type="text" name="email" id="email" v-model="registerForm.email" required>
      </div>
      <div>
        <label for="email2">{{ $t('repeat_email') }}</label>
        <input type="text" name="email_confirmation" id="email_confirmation" v-model="registerForm.email_confirmation" required>
      </div>
      <div>
        <label for="password">{{ $t('password') }}</label>
        <input type="password" name="password" id="password" v-model="registerForm.password" minlength="8" required>
      </div>
      <div style="display: flex; margin-top: 16px">
        <input style="flex: 15px; margin: 0 10px 0 0" type="checkbox" name="terms" id="terms" required />
        <i18n style="margin: 0"  path="accept_terms" tag="label" for="terms">
          <a style="text-decoration: underline" href="terms" target="_blank">{{ $t('terms') }}</a>
        </i18n>
      </div>
      <div>
        <button type="submit" class="cta-big">
          {{ $t('sign_in') }}
        </button>
      </div>
      <label style="text-align:center"><a @click="mode='login'" class="link">{{ $t('already_registered') }}</a></label>
    </form>
    <form v-if="!authenticated && mode === 'reset' && !loading" action="#" @submit.prevent="doReset">
      <div>
        <label for="email">{{ $t('email') }}</label>
        <input type="text" name="email" id="email" v-model="resetForm.email" required>
      </div>
      <div>
        <button type="submit" class="cta-big">
          {{ $t('send_reset_password') }}
        </button>
      </div>
    </form>
    <form v-if="authenticated" action="#" @submit.prevent="doLogOut">
      <button type="submit">
        {{ $t('logout') }}
      </button>
    </form>
  </div>
</template>

<script>

import LoadingLogo from '@/components/LoadingLogo'
import ErrorMessage from '@/components/ErrorMessage'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Login',
  components: {
    ErrorMessage,
    LoadingLogo
  },
  data () {
    return {
      loginForm: {
        email: '',
        password: ''
      },
      registerForm: {
        email: '',
        email_confirmation: '',
        name: '',
        password: ''
      },
      resetForm: {
        email: ''
      },
      mode: 'register',
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      error: 'auth/error'
    })
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
      signOut: 'auth/signOut',
      register: 'auth/register',
      recoverPassword: 'auth/recoverPassword'
    }),

    async doLogIn () {
      this.loading = true
      await this.signIn(this.loginForm)
      this.loading = false
    },
    async doRegister () {
      this.loading = true
      await this.register(this.registerForm)
      this.loading = false
    },
    async doReset () {
      this.loading = true
      await this.recoverPassword(this.resetForm)
      this.loading = false
    },
    async doLogOut () {
      await this.signOut()

      this.$router.replace('/')
    }
  }
}
</script>

<style scoped lang="scss">
label, input, button, textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
}

label, button{
  margin-top: 10px;

  &.cta-big{
    margin-top: 20px;
  }

}

.tab {
  border-top: 2px solid #2c3e5044;
  top: -20px;
  position: relative;
  margin-right: 24px;
  font-weight: bold;
  color: #999;
  user-select: none;
  transition: border 0.2s;

  &.selected {
    border-top: 5px solid #2c3e50;
    color: #2c3e50;
    border-radius: 3px;
  }
}

</style>
