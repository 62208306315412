<template>
  <Modal @close="$emit('close')" :showsCloseButton="!isTranslating">
    <div slot="body" id="modal-content" v-bind:class="{ centertext: isTranslating }">
      <h1 class="notranslate">{{ isTranslating ? $t('translating') : $t('select_language') }}</h1>
      <div id="iframe-container">
        <iframe id="translate-frame" src="/translate.html"/>
        <div id="scroll-cover"></div>
      </div>
      <p id="translating-msg" v-if="isTranslating">{{ $t('transation_wait') }}</p>
    </div>
    <div slot="footer" >
      <input v-if="!isTranslating" type="checkbox" id="keep-formatting" name="keep-formatting" v-model="keepFormattingEnabled">
      <label v-if="!isTranslating" for="keep-formatting">{{ $t('keep_formatting') }}</label>
      <button v-if="!isTranslating" class="cta-big" id="confirm-button" @click="extractData">{{ $t('auto_translate') }}</button>
    </div>
  </Modal>
</template>

<script>
import Modal from '../modals/Modal.vue'

export default {
  name: 'AutoTranslate',
  components: {
    Modal
  },
  props: {
    originals: {}
  },
  data: function () {
    return {
      isTranslating: false,
      keepFormattingEnabled: true
    }
  },
  methods: {
    injectDataToIframe: function () {
      const iframeDoc = document.getElementById('translate-frame').contentDocument
      const translationTable = iframeDoc.getElementById('translate-table')
      translationTable.innerHTML = ''
      for (var i = 0; i < this.originals.length; i++) {
        var row = document.createElement('tr')
        var cell = document.createElement('td')
        cell.className = 'translated'
        if (this.originals[i].text !== undefined && this.originals[i].text.length > 0) {
          cell.innerHTML = this.$sanitize(this.originals[i].text)
        } else {
          cell.innerHTML = ''
        }
        row.appendChild(cell)
        translationTable.appendChild(row)
      }
    },
    extractData: function () {
      this.isTranslating = true
      document.getElementById('translate-frame').contentWindow.scrollToEnd()
    },
    onTranslationComplete: function (evt) {
      if (evt.data === 'done') {
        const iframeDoc = document.getElementById('translate-frame').contentDocument
        const tranlsatedEls = Array.from(iframeDoc.getElementsByClassName('translated'))
        const translated = tranlsatedEls.map(function (item) { return item.innerText })
        this.$emit('on-receive-translations', { translations: translated, shouldMantainFormattingTags: this.keepFormattingEnabled })
        this.isTranslating = false
      }
    }
  },
  mounted: function () {
    window.addEventListener('message', this.onTranslationComplete, false)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

h1 {
  margin: 6px 0;
}
.centertext{
  text-align: center;
}
#iframe-container{
  flex: 1;
  position: relative;
  #scroll-cover {
    position: absolute;
    top: 0;
    right: -1px;
    width: 22px;
    height: 100%;
    background-color: white;
  }
  iframe {
    width: 100%;
    border: none;
    height: 105px;
  }
}
#confirm-button{
  margin-top: 16px;
  width: 100%;
}
#translating-msg {
  padding-bottom: 20px
}

</style>
