import axios from 'axios'

const httpClient = axios.create({
  baseURL: '/api',
  timeout: 20000, // 20 secs
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

export default httpClient
