/**
 * @author Phil Teare
 * using wikipedia data
 */
const isoLangs = {
  ab: {
    name: 'Abkhaz',
    nativename: 'аҧсуа'
  },
  aa: {
    name: 'Afar',
    nativename: 'Afaraf'
  },
  af: {
    name: 'Afrikaans',
    nativename: 'Afrikaans'
  },
  ak: {
    name: 'Akan',
    nativename: 'Akan'
  },
  sq: {
    name: 'Albanès',
    nativename: 'Shqip'
  },
  am: {
    name: 'Amharic',
    nativename: 'አማርኛ'
  },
  ar: {
    name: 'Àrab',
    nativename: 'العربية'
  },
  an: {
    name: 'Aragonès',
    nativename: 'Aragonés'
  },
  hy: {
    name: 'Armeni',
    nativename: 'Հայերեն'
  },
  as: {
    name: 'Assamese',
    nativename: 'অসমীয়া'
  },
  av: {
    name: 'Avaric',
    nativename: 'авар мацӀ, магӀарул мацӀ'
  },
  ae: {
    name: 'Avestan',
    nativename: 'avesta'
  },
  ay: {
    name: 'Aymara',
    nativename: 'aymar aru'
  },
  az: {
    name: 'Azerbaijani',
    nativename: 'azərbaycan dili'
  },
  bm: {
    name: 'Bambara',
    nativename: 'bamanankan'
  },
  ba: {
    name: 'Bashkir',
    nativename: 'башҡорт теле'
  },
  eu: {
    name: 'Basc',
    nativename: 'euskara, euskera'
  },
  be: {
    name: 'Bielorús',
    nativename: 'Беларуская'
  },
  bn: {
    name: 'Bengali',
    nativename: 'বাংলা'
  },
  bh: {
    name: 'Bihari',
    nativename: 'भोजपुरी'
  },
  bi: {
    name: 'Bislama',
    nativename: 'Bislama'
  },
  bs: {
    name: 'Bosnià',
    nativename: 'bosanski jezik'
  },
  br: {
    name: 'Bretó',
    nativename: 'brezhoneg'
  },
  bg: {
    name: 'Búlgar',
    nativename: 'български език'
  },
  my: {
    name: 'Burmese',
    nativename: 'ဗမာစာ'
  },
  ca: {
    name: 'Català',
    nativename: 'Català'
  },
  ch: {
    name: 'Chamorro',
    nativename: 'Chamoru'
  },
  ce: {
    name: 'Chechen',
    nativename: 'нохчийн мотт'
  },
  ny: {
    name: 'Chichewa; Chewa; Nyanja',
    nativename: 'chiCheŵa, chinyanja'
  },
  zh: {
    name: 'Xinès',
    nativename: '中文 (Zhōngwén), 汉语, 漢語'
  },
  cn: {
    name: 'Xinès',
    nativename: '中文 (Zhōngwén), 汉语, 漢語'
  },
  cv: {
    name: 'Chuvash',
    nativename: 'чӑваш чӗлхи'
  },
  kw: {
    name: 'Còrnic',
    nativename: 'Kernewek'
  },
  co: {
    name: 'Cors',
    nativename: 'corsu, lingua corsa'
  },
  cr: {
    name: 'Cree',
    nativename: 'ᓀᐦᐃᔭᐍᐏᐣ'
  },
  hr: {
    name: 'Croat',
    nativename: 'hrvatski'
  },
  cs: {
    name: 'Txec',
    nativename: 'česky, čeština'
  },
  da: {
    name: 'Danès',
    nativename: 'dansk'
  },
  dv: {
    name: 'Divehi; Dhivehi; Maldivian;',
    nativename: 'ދިވެހި'
  },
  nl: {
    name: 'Neerlandès',
    nativename: 'Nederlands, Vlaams'
  },
  en: {
    name: 'Anglès',
    nativename: 'English'
  },
  eo: {
    name: 'Esperanto',
    nativename: 'Esperanto'
  },
  et: {
    name: 'Estonià',
    nativename: 'eesti, eesti keel'
  },
  ee: {
    name: 'Ewe',
    nativename: 'Eʋegbe'
  },
  fo: {
    name: 'Feroès',
    nativename: 'føroyskt'
  },
  fj: {
    name: 'Fijià',
    nativename: 'vosa Vakaviti'
  },
  fi: {
    name: 'Finlandès',
    nativename: 'suomi, suomen kieli'
  },
  fr: {
    name: 'Francès',
    nativename: 'français, langue française'
  },
  ff: {
    name: 'Fula; Fulah; Pulaar; Pular',
    nativename: 'Fulfulde, Pulaar, Pular'
  },
  gl: {
    name: 'Gallec',
    nativename: 'Galego'
  },
  ka: {
    name: 'Georgià',
    nativename: 'ქართული'
  },
  de: {
    name: 'Alemany',
    nativename: 'Deutsch'
  },
  el: {
    name: 'Grec',
    nativename: 'Ελληνικά'
  },
  gn: {
    name: 'Guaraní',
    nativename: 'Avañeẽ'
  },
  gu: {
    name: 'Gujarati',
    nativename: 'ગુજરાતી'
  },
  ht: {
    name: 'Crioll haitià',
    nativename: 'Kreyòl ayisyen'
  },
  ha: {
    name: 'Hausa',
    nativename: 'Hausa, هَوُسَ'
  },
  he: {
    name: 'Hebreu',
    nativename: 'עברית'
  },
  hz: {
    name: 'Herero',
    nativename: 'Otjiherero'
  },
  hi: {
    name: 'Hindi',
    nativename: 'हिन्दी, हिंदी'
  },
  ho: {
    name: 'Hiri Motu',
    nativename: 'Hiri Motu'
  },
  hu: {
    name: 'Hongarès',
    nativename: 'Magyar'
  },
  ia: {
    name: 'Interlingua',
    nativename: 'Interlingua'
  },
  id: {
    name: 'Indonesi',
    nativename: 'Bahasa Indonesia'
  },
  ie: {
    name: 'Interlingue',
    nativename: 'Originally called Occidental; then Interlingue after WWII'
  },
  ga: {
    name: 'Gaèlic irlandès',
    nativename: 'Gaeilge'
  },
  ig: {
    name: 'Igbo',
    nativename: 'Asụsụ Igbo'
  },
  ik: {
    name: 'Inupiaq',
    nativename: 'Iñupiaq, Iñupiatun'
  },
  io: {
    name: 'Ido',
    nativename: 'Ido'
  },
  is: {
    name: 'Islandès',
    nativename: 'Íslenska'
  },
  it: {
    name: 'Italià',
    nativename: 'Italiano'
  },
  iu: {
    name: 'Inuktitut',
    nativename: 'ᐃᓄᒃᑎᑐᑦ'
  },
  ja: {
    name: 'Japonès',
    nativename: '日本語 (にほんご／にっぽんご)'
  },
  jv: {
    name: 'Javanese',
    nativename: 'basa Jawa'
  },
  kl: {
    name: 'Kalaallisut, Greenlandic',
    nativename: 'kalaallisut, kalaallit oqaasii'
  },
  kn: {
    name: 'Kannada',
    nativename: 'ಕನ್ನಡ'
  },
  kr: {
    name: 'Kanuri',
    nativename: 'Kanuri'
  },
  ks: {
    name: 'Caixmiri',
    nativename: 'कश्मीरी, كشميري‎'
  },
  kk: {
    name: 'Kazakh',
    nativename: 'Қазақ тілі'
  },
  km: {
    name: 'Khmer',
    nativename: 'ភាសាខ្មែរ'
  },
  ki: {
    name: 'Kikuyu, Gikuyu',
    nativename: 'Gĩkũyũ'
  },
  rw: {
    name: 'Kinyarwanda',
    nativename: 'Ikinyarwanda'
  },
  ky: {
    name: 'Kirghiz, Kyrgyz',
    nativename: 'кыргыз тили'
  },
  kv: {
    name: 'Komi',
    nativename: 'коми кыв'
  },
  kg: {
    name: 'Kongo',
    nativename: 'KiKongo'
  },
  ko: {
    name: 'Coreà',
    nativename: '한국어 (韓國語), 조선말 (朝鮮語)'
  },
  ku: {
    name: 'Kurd',
    nativename: 'Kurdî, كوردی‎'
  },
  kj: {
    name: 'Kwanyama, Kuanyama',
    nativename: 'Kuanyama'
  },
  la: {
    name: 'Llatí',
    nativename: 'latine, lingua latina'
  },
  lb: {
    name: 'Luxemburguès',
    nativename: 'Lëtzebuergesch'
  },
  lg: {
    name: 'Luganda',
    nativename: 'Luganda'
  },
  li: {
    name: 'Limburgish, Limburgan, Limburger',
    nativename: 'Limburgs'
  },
  ln: {
    name: 'Lingala',
    nativename: 'Lingála'
  },
  lo: {
    name: 'Lao',
    nativename: 'ພາສາລາວ'
  },
  lt: {
    name: 'Lituà',
    nativename: 'lietuvių kalba'
  },
  lu: {
    name: 'Luba-Katanga',
    nativename: ''
  },
  lv: {
    name: 'Letó',
    nativename: 'latviešu valoda'
  },
  gv: {
    name: 'Manx',
    nativename: 'Gaelg, Gailck'
  },
  mk: {
    name: 'Macedoni',
    nativename: 'македонски јазик'
  },
  mg: {
    name: 'Malagasy',
    nativename: 'Malagasy fiteny'
  },
  ms: {
    name: 'Malai',
    nativename: 'bahasa Melayu, بهاس ملايو‎'
  },
  ml: {
    name: 'Malaiàlam',
    nativename: 'മലയാളം'
  },
  mt: {
    name: 'Maltès',
    nativename: 'Malti'
  },
  mi: {
    name: 'Māori',
    nativename: 'te reo Māori'
  },
  mr: {
    name: 'Marathi (Marāṭhī)',
    nativename: 'मराठी'
  },
  mh: {
    name: 'Marshallese',
    nativename: 'Kajin M̧ajeļ'
  },
  mn: {
    name: 'Mongol',
    nativename: 'монгол'
  },
  na: {
    name: 'Nauru',
    nativename: 'Ekakairũ Naoero'
  },
  nv: {
    name: 'Navajo, Navaho',
    nativename: 'Diné bizaad, Dinékʼehǰí'
  },
  nb: {
    name: 'Norwegian Bokmål',
    nativename: 'Norsk bokmål'
  },
  nd: {
    name: 'North Ndebele',
    nativename: 'isiNdebele'
  },
  ne: {
    name: 'Nepalès',
    nativename: 'नेपाली'
  },
  ng: {
    name: 'Ndonga',
    nativename: 'Owambo'
  },
  nn: {
    name: 'Norwegian Nynorsk',
    nativename: 'Norsk nynorsk'
  },
  no: {
    name: 'Noruec',
    nativename: 'Norsk'
  },
  ii: {
    name: 'Nuosu',
    nativename: 'ꆈꌠ꒿ Nuosuhxop'
  },
  nr: {
    name: 'South Ndebele',
    nativename: 'isiNdebele'
  },
  oc: {
    name: 'Occità',
    nativename: 'Occitan'
  },
  oj: {
    name: 'Ojibwe, Ojibwa',
    nativename: 'ᐊᓂᔑᓈᐯᒧᐎᓐ'
  },
  cu: {
    name: 'Old Church Slavonic, Church Slavic, Church Slavonic, Old Bulgarian, Old Slavonic',
    nativename: 'ѩзыкъ словѣньскъ'
  },
  om: {
    name: 'Oromo',
    nativename: 'Afaan Oromoo'
  },
  or: {
    name: 'Oriya',
    nativename: 'ଓଡ଼ିଆ'
  },
  os: {
    name: 'Osseta, Osset',
    nativename: 'ирон æвзаг'
  },
  pa: {
    name: 'Panjabi',
    nativename: 'ਪੰਜਾਬੀ, پنجابی‎'
  },
  pi: {
    name: 'Pali',
    nativename: 'पाऴि'
  },
  fa: {
    name: 'Persa',
    nativename: 'فارسی'
  },
  pl: {
    name: 'Polonès',
    nativename: 'polski'
  },
  ps: {
    name: 'Paixtu',
    nativename: 'پښتو'
  },
  pt: {
    name: 'Portuguès',
    nativename: 'Português'
  },
  qu: {
    name: 'Quítxua',
    nativename: 'Runa Simi, Kichwa'
  },
  rm: {
    name: 'Romanx',
    nativename: 'rumantsch grischun'
  },
  rn: {
    name: 'Kirundi',
    nativename: 'kiRundi'
  },
  ro: {
    name: 'Romanès, Moldau',
    nativename: 'română'
  },
  ru: {
    name: 'Rus',
    nativename: 'русский язык'
  },
  sa: {
    name: 'Sànscrit',
    nativename: 'संस्कृतम्'
  },
  sc: {
    name: 'Sard',
    nativename: 'sardu'
  },
  sd: {
    name: 'Sindhi',
    nativename: 'सिन्धी, سنڌي، سندھی‎'
  },
  se: {
    name: 'Sami septentrional',
    nativename: 'Davvisámegiella'
  },
  sm: {
    name: 'Samoà',
    nativename: 'gagana faa Samoa'
  },
  sg: {
    name: 'Sango',
    nativename: 'yângâ tî sängö'
  },
  sr: {
    name: 'Serb',
    nativename: 'српски језик'
  },
  gd: {
    name: 'Gaèlic escocès',
    nativename: 'Gàidhlig'
  },
  sn: {
    name: 'Xona',
    nativename: 'chiShona'
  },
  si: {
    name: 'Singalès, Sinhala',
    nativename: 'සිංහල'
  },
  sk: {
    name: 'Eslovac',
    nativename: 'slovenčina'
  },
  sl: {
    name: 'Eslovè',
    nativename: 'slovenščina'
  },
  so: {
    name: 'Somali',
    nativename: 'Soomaaliga, af Soomaali'
  },
  st: {
    name: 'Sesotho',
    nativename: 'Sesotho'
  },
  es: {
    name: 'Castellà',
    nativename: 'español, castellano'
  },
  su: {
    name: 'Sondanès',
    nativename: 'Basa Sunda'
  },
  sw: {
    name: 'Swahili',
    nativename: 'Kiswahili'
  },
  ss: {
    name: 'Swati',
    nativename: 'SiSwati'
  },
  sv: {
    name: 'Suec',
    nativename: 'svenska'
  },
  ta: {
    name: 'Tamil',
    nativename: 'தமிழ்'
  },
  te: {
    name: 'Telugu',
    nativename: 'తెలుగు'
  },
  tg: {
    name: 'Tajik',
    nativename: 'тоҷикӣ, toğikī, تاجیکی‎'
  },
  th: {
    name: 'Tailandès',
    nativename: 'ไทย'
  },
  ti: {
    name: 'Tigrinya',
    nativename: 'ትግርኛ'
  },
  bo: {
    name: 'Tibetà',
    nativename: 'བོད་ཡིག'
  },
  tk: {
    name: 'Turcman',
    nativename: 'Türkmen, Түркмен'
  },
  tl: {
    name: 'Tagal',
    nativename: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔'
  },
  tn: {
    name: 'Tswana',
    nativename: 'Setswana'
  },
  to: {
    name: 'Tongalès',
    nativename: 'faka Tonga'
  },
  tr: {
    name: 'Turc',
    nativename: 'Türkçe'
  },
  ts: {
    name: 'Tsonga',
    nativename: 'Xitsonga'
  },
  tt: {
    name: 'Tatar',
    nativename: 'татарча, tatarça, تاتارچا‎'
  },
  tw: {
    name: 'Twi',
    nativename: 'Twi'
  },
  ty: {
    name: 'Tahitià',
    nativename: 'Reo Tahiti'
  },
  ug: {
    name: 'Uigur',
    nativename: 'Uyƣurqə, ئۇيغۇرچە‎'
  },
  uk: {
    name: 'Ucraïnès',
    nativename: 'українська'
  },
  ur: {
    name: 'Urdu',
    nativename: 'اردو'
  },
  uz: {
    name: 'Uzbek',
    nativename: 'zbek, Ўзбек, أۇزبېك‎'
  },
  ve: {
    name: 'Venda',
    nativename: 'Tshivenḓa'
  },
  vi: {
    name: 'Vietnamita',
    nativename: 'Tiếng Việt'
  },
  vo: {
    name: 'Volapük',
    nativename: 'Volapük'
  },
  wa: {
    name: 'Való',
    nativename: 'Walon'
  },
  cy: {
    name: 'Gal·lès',
    nativename: 'Cymraeg'
  },
  wo: {
    name: 'Wolof',
    nativename: 'Wollof'
  },
  fy: {
    name: 'Western Frisian',
    nativename: 'Frysk'
  },
  xh: {
    name: 'Xhosa',
    nativename: 'isiXhosa'
  },
  yi: {
    name: 'Yiddish',
    nativename: 'ייִדיש'
  },
  yo: {
    name: 'Yoruba',
    nativename: 'Yorùbá'
  },
  za: {
    name: 'Zhuang, Chuang',
    nativename: 'Saɯ cueŋƅ, Saw cuengh'
  }
}

export { isoLangs }
