<template>
  <component :is="isExternalLink ? 'a' : 'router-link'" class="banner" :to="isExternalLink ? '' : href" :href="href">
    <div class="banner-content">
      <h1><i :class="icon"></i> {{ title }}</h1>
      <p>{{ subtitle }}</p>
      <span class="cta">{{ cta }} <i class="fas fa-arrow-right"></i></span>
    </div>
  </component>
</template>

<script>

export default {
  name: 'Banner',
  props: {
    title: String,
    subtitle: String,
    cta: String,
    icon: String,
    href: String
  },
  computed: {
    isExternalLink: function () {
      return this.href.startsWith('http') && this.href.indexOf(':') >= 0
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.banner {
  display: block;
  width: 100%;
  padding: 20px 20px 30px 20px;
  box-sizing: border-box;
  .banner-content {
    position: relative;
    width: 80%;
    margin: 0 auto;
    user-select: none;
    color: white;
    @media (max-width: 768px) {
      width: 95%;
      text-align: center;
      h1 {
        font-size: 1.5em;
      }
    }
  }
  .cta {
    padding: 8px 16px;
    border-radius: 32px;
    border: 1px solid;
    color: white;
    font-weight: bold;
    position: absolute;
    right: 20px;
    top: 16px;
    @media (max-width: 768px) {
      display: none;
    }
  }
}
</style>
