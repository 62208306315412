import httpClient from './httpClient'
import { stringify } from 'subtitle'

const END_POINT = '/subtitle'

const getPopularSubtitles = (limit) => httpClient.get(END_POINT, { params: { sort: 'popular', limit: limit } })

const getLatestSubtitles = (limit) => httpClient.get(END_POINT, { params: { sort: 'latest', limit: limit } })

const createSubtitle = (metadata, data, originalData) => httpClient.post(END_POINT, { metadata, data, original_data: originalData })

const createRawSubtitle = async (metadata, file) => {
  const data = new FormData()
  const metadataBlob = new Blob([JSON.stringify(metadata)], {
    type: 'application/json'
  })
  data.append('metadata', metadataBlob)
  data.append('raw-file', file)
  await httpClient({
    method: 'post',
    url: END_POINT,
    data: data
  })
}

const previewSubtitle = (subtitleId) => httpClient.get(END_POINT + '-preview/' + subtitleId)

const updateSubtitle = (subtitle) => httpClient.put(END_POINT + '/' + subtitle.id, subtitle)

const deleteSubtitle = (subtitle) => httpClient.delete(END_POINT + '/' + subtitle.id, subtitle)

const getFileData = async (fileRef) => {
  const response = await httpClient.get('/download/' + fileRef)
  try {
    const data = stringify(response.data)
    return encodeURIComponent(data)
  } catch (err) {
    console.log(err)
  }
  return encodeURIComponent(response.data)
}

export {
  getPopularSubtitles,
  getLatestSubtitles,
  createSubtitle,
  createRawSubtitle,
  updateSubtitle,
  deleteSubtitle,
  previewSubtitle,
  getFileData
}
