<template>
  <div class="route-content">
    <div id="header" :style="backdropUrl ? 'background-image: url(\''+ backdropUrl +'\')' : ''">
        <div id="header-content">
            <img v-if="media" :src="posterUrl" />
            <div id="header-info">
                <h1 v-if="media" class="media-title">{{ media.title }}</h1>
                <p class="media-original-title" v-if="media && media.original_title && media.original_title !== media.title">{{ media.original_title }}</p>
                <p v-if="media && media.type">{{ $t(media.type) }}</p>
                <p v-if="media && media.year">{{ media.year }}</p>
                <div id="header-actions">
                  <a v-if="media.tmdb_id" target="_blank" :href="tmdbLink">{{ $t('tmdb_page') }}<i class="fas fa-external-link-alt"></i></a>
                  <a v-if="userIsAdmin" :href="'/admin/media/'+media.slug">{{ $t('edit') }}<i class="fas fa-pencil-alt"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div id="subs" v-if="media.subtitles && media.subtitles.length > 0">
      <h1 id="subs-title">Subtítols en català de <span style="font-weight: bold">{{ media.title }}</span>:</h1>
      <div class="subs-list" v-if="media.type !== 'tv'">
        <SubtitleEntry v-for='s in media.subtitles' v-bind:key='s.id' :subtitle="s" />
      </div>
      <div v-if="media.type == 'tv' && groupedMedia.length > 0">
        <details class="season-entry subs-list" v-for='season in groupedMedia' v-bind:key='season.key' open>
          <summary class="season-title">{{ $t('season') + ' ' + season.key }}</summary>
          <SubtitleEntry v-for='s in season.values' v-bind:key='s.id' :subtitle="s" />
        </details>
      </div>
    </div>
    <LoadingLogo v-if="!media.subtitles" style="padding: 40px 0" />
    <div v-if="media.subtitles && media.subtitles.length == 0" id="empty-view">
      <p class="icon"><i class="fas fa-heart-broken"></i></p>
      <p>{{ $t('subtitles_not_available') }} <span style="font-weight: bold">{{ media.title }}</span>.</p>
    </div>
    <div id="media-info" v-if="media.subtitles && media.subtitles.length > 0">
      <h2>{{ $t('info') }}</h2>
      <p>
        <span class="info-label">{{ $t('title') }}</span>:
        <span class="info-content">{{ media.title }}</span>
      </p>
      <p>
        <span class="info-label">{{ $t('original_title') }}</span>:
        <span class="info-content">{{ media.original_title }}</span>
      </p>
      <p>
        <span class="info-label">{{ $t('type') }}</span>:
        <span class="info-content">{{ $t(media.type) }}</span>
      </p>
      <p>
        <span class="info-label">{{ $t('year') }}</span>:
        <span class="info-content">{{ media.year }}</span>
      </p>
      <p>
        <span class="info-label">{{ $t('original_language') }}</span>:
        <router-link :to="{ name: 'SearchResults', query: { original_language: media.original_language } }">
          <span class="info-tag">{{ originalLanguage }}</span>
        </router-link>
      </p>
      <p id="media-genres">
        <span class="info-label">{{ $t('genres') }}</span>:
        <router-link v-for="g in media.genres" v-bind:key="g.id" :to="{ name: 'SearchResults', query: { genre: g.id, collection_name: g.name } }">
          <span class="info-tag">{{ g.name }}</span>
        </router-link>
      </p>
      <p id="tmdb-link">
        <a v-if="media.tmdb_id" target="_blank" :href="tmdbLink" class="info-link">
          {{ $t('tmdb_complete_page') }}
          <img src="https://www.themoviedb.org/assets/2/v4/logos/v2/blue_short-8e7b30f73a4020692ccca9c88bafe5dcb6f8a62a4c6bc55cd9ba82bb2cd95f6c.svg"/>
          <i class="fas fa-external-link-alt"></i>
        </a>
      </p>
    </div>
    <div id="banners" v-if="media.subtitles">
      <Banner id="twitter-banner" title="Segueix-nos a Twitter!" subtitle="Segueix-nos a Twitter per rebre totes les novetats!" cta="Segueix" icon="fab fa-twitter" href="https://twitter.com/sublliure" target="_blank" style="background-color: #42b983" />
      <Banner id="contribute-banner" title="Participa!" subtitle="Anima't a participar traduint o pujant subtítols!" cta="Participa" icon="fas fa-globe-europe" href="/participa" style="background-color: #2c3e50" />
    </div>
  </div>
</template>

<script>

import { getMedia } from '@/libs/api/media.api'
import { groupByArray } from '@/libs/helpers'
import LoadingLogo from '@/components/LoadingLogo'
import Banner from '@/components/Banner'
import SubtitleEntry from '@/components/SubtitleEntry.vue'
import { isoLangs } from '@/libs/iso-langs'
import { mapGetters } from 'vuex'

export default {
  name: 'MediaDetail',
  components: {
    SubtitleEntry,
    LoadingLogo,
    Banner
  },
  data: function () {
    return {
      mediaSlug: this.$route.params.slug,
      media: this.$route.params.media ?? {},
      groupedMedia: [],
      isoLangs: isoLangs
    }
  },
  metaInfo: function () {
    return {
      title: this.pageTitle,
      titleTemplate: '%s - Subtítols en català | Sublliure.cat',
      description: 'Subtítols en català de ' + this.media.title + ' (' + this.media.year + '). Descarrega els subtítols de les últimes sèries i pel·lícules. Participa compartint i traduint subtítols.',
      meta: [
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: 'https://image.tmdb.org/t/p/w154/' + this.media.poster_url, vmid: 'og:image' },
        { property: 'og:title', content: this.media.title, vmid: 'og:title' },
        { property: 'og:description', content: 'Subtítols en català de ' + this.media.title, vmid: 'og:description' }
      ]
    }
  },
  beforeMount: function () {
    this.fetchMedia()
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mediaSlug = to.params.slug
    this.media = to.params.media
    this.fetchMedia()
    next()
  },
  methods: {
    fetchMedia: async function () {
      const media = await getMedia(this.mediaSlug)
      this.media = media.data
      if (this.media.type === 'tv') {
        const orderedSubtitles = this.media.subtitles.sort((a, b) => (a.episode > b.episode ? 1 : -1))
        this.groupedMedia = groupByArray(orderedSubtitles, 'season').sort((a, b) => (a.key > b.key ? 1 : -1))
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),
    posterUrl: function () {
      if (this.media.poster_url) {
        return 'https://image.tmdb.org/t/p/w185' + this.media.poster_url
      }
      return '/poster_placeholder.png'
    },
    backdropUrl: function () {
      if (this.media.poster_url) {
        return 'https://image.tmdb.org/t/p/w1920_and_h800_multi_faces' + this.media.backdrop_url
      }
      return null
    },
    tmdbLink: function () {
      return 'https://www.themoviedb.org/' + this.media.type + '/' + this.media.tmdb_id
    },
    pageTitle: function () {
      if (this.media.title === null || this.media.title === undefined) {
        return document.title
      } else if (this.media.year === null || this.media.year === undefined) {
        return this.media.title
      }
      return this.media.title + ' (' + this.media.year + ')'
    },
    originalLanguage: function () {
      const langCode = this.media.original_language
      if (langCode) {
        return this.isoLangs[langCode] ? this.isoLangs[langCode].name : ''
      }
      return ''
    },
    userIsAdmin: function () {
      return this.user && this.user.name === 'subbot'
    }
  }
}

</script>

<style scoped lang="scss">
.route-content {
  width: 100%;
  padding-bottom: 0 !important;
  .media-title {
    margin-top: -2px;
    font-size: 3em;
    @media (max-width: 768px) {
      font-size: 2em;
      margin-top: 4px;
    }
  }
  .media-original-title {
    position: relative;
    margin-top: -12px;
    margin-bottom: 8px;
    @media (max-width: 768px) {
      margin-top: -6px;
    }
  }

  #header {
    width: 100%;
    background: #42b983;
    background-position: right -200px center;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      background-position: center;
    }

    #header-content {
      width: 100%;
      margin: 0 auto;
      display: flex;
      padding: 20px 10%;
      box-sizing: border-box;
      color: white;
      background-image: linear-gradient(to right, #42b983 150px, rgba(44, 62, 80, 0.84) 100%);
      @media (max-width: 768px) {
        display: block;
        text-align: center;
        padding: 20px 5px;
        background-image: linear-gradient(to right, #42b983, rgba(44, 62, 80, 0.84) 100%);
      }
      img {
        margin-right: 20px;
        border-radius: 7px;
        height: 138px;
        min-width: 92px;
        box-shadow: 0px 3px 5px #00000066;
        @media (max-width: 768px) {
          margin-right: 0;
          height: 108px;
          min-width: 71px;
        }
      }

      #header-info{
        width: 100%;
        position: relative;
        h1, p{
          text-shadow: 0px 0px 10px #2c3e5066;
        }
        @media (max-width: 768px) {
          h1 {
            font-size: 1.6em;
          }
        }
      }

      #header-actions {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
          display: block;
          position: relative;
          margin-top: 8px;
        }
        a{
          color: white;
          border: 1px solid;
          padding: 8px 16px;
          border-radius: 8px;
          @media (max-width: 768px) {
            padding: 2px 6px;
          }
        }

        i{
          margin-left: 6px;
        }

      }

    }
  }

  #subs {
    width: 80%;
    margin: 0 auto;
    padding: 10px 0 16px 0;
    border-bottom: 1px dashed #ccc;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      width: 100%;
      padding: 0;
      border-bottom: 1px solid #eee;
    }

    i {
      padding-right: 8px;
    }

    .subs-list{
      border-radius: 4px;
      box-shadow: 0 1px 4px #ccc;
      background: white;
      @media (max-width: 768px) {
        border-radius: 0;
      }
    }

    #subs-title{
      color: #2c3e50;
      font-weight: 300;
      font-size: 1.2em;
      margin-top: 20px;
      margin-bottom: 8px;
      @media (max-width: 768px) {
        padding: 0 10px;
      }
    }
    .season-entry:not(:first-child){
      margin-top: 12px;
    }
    .season-title{
      cursor: pointer;
      font-size: 1em;
      font-weight: bold;
      padding: 16px;
      @media (max-width: 768px) {
        border-radius: 0;
      }
    }
  }

  #media-info{
    width: 80%;
    margin: 0 auto;
    padding: 16px 8px 16px 16px;
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 1px 4px #ccc;
    background: white;
    position: relative;
    @media (max-width: 768px) {
      margin-top: 20px;
      width: 100%;
      border-radius: 0;
      border-top: 1px solid #eee;
    }

    h2{
      color: #2c3e50;
      font-weight: 300;
      font-size: 1.2em;
      text-transform: uppercase;
      border-bottom: 1px solid #2c3e5019;
      margin-bottom: 10px;
    }
    .info-label{
      font-weight: bold;
    }
    #media-genres{
      overflow-x: scroll;
    }
    .info-tag{
      background: white;
      margin-right: 4px;
      padding: 0 4px;
      border: 1px solid #ccc;
      border-radius: 16px;
      white-space: nowrap;
      font-size: 0.9em;
    }
    #tmdb-link{
      @media (max-width: 768px) {
        margin-top: 10px;
      }
    }
    .info-link{
      position: absolute;
      top: 15px;
      right: 15px;
      color: #8fcea1;
      font-weight: bold;
      background: white;
      img{
        height: 0.8em;
      }
      i{
        color: #01b3e5;
        margin-left: 8px;
      }
      @media (max-width: 768px) {
        position: static;
        border-bottom: 2px solid;
        margin-bottom: 10px;
      }
    }
  }

  #banners{
    margin-top: 30px;
  }

  #empty-view{
    padding: 30px 0 50px 0;
    text-align: center;
    .icon{
      font-size: 5em;
    }
  }

}
</style>
