<template>
  <component :is="linkEnabled ? 'router-link' : 'div'" :class="'media-card '+styling" :to="{ name: 'MediaDetail', params: { slug: this.media.slug, media: this.media } }">
    <img v-lazy="posterUrl" :style="'width:'+imgWidthStyled+';height:'+imgHeightStyled"/>
    <div class="card-info">
      <div class="title">
        <h2>{{ media.title }}</h2>
        <p class="episode" v-if="showsEpisodeInfo && subtitle && subtitle.season > 0 && subtitle.episode > 0" :title="$t('season') + ' ' + subtitle.season + ', ' + $t('episode') + ' ' + subtitle.episode">{{ subtitle.season }}x{{ subtitle.episode }}</p>
      </div>
      <slot class="extras" name="extras"></slot>
      <p class="media-type" v-if="media.type">{{ $t(media.type) }}</p>
      <p class="media-year" v-if="media.year">{{ media.year }}</p>
      <p class="media-time-ago" v-if="subtitle && subtitle.updated_at" :title="localizedDate">{{ timeAgoLabel }}</p>
    </div>
  </component>
</template>

<script>

export default {
  name: 'SearchResult',
  props: {
    media: Object,
    subtitle: Object,
    imgHeight: String,
    imgWidth: String,
    showsEpisodeInfo: {
      type: Boolean,
      default: false
    },
    linkEnabled: {
      type: Boolean,
      default: true
    },
    styling: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    posterUrl: function () {
      if (this.media.poster_url) {
        return 'https://image.tmdb.org/t/p/w92' + this.media.poster_url
      }
      return '/poster_placeholder.png'
    },
    imgWidthStyled: function () {
      switch (this.styling) {
        case 'default':
          return this.imgWidth == null ? '54px' : this.imgWidth
        case 'minimal':
          return this.imgWidth == null ? '30.667px' : this.imgWidth
        default:
          return '54px'
      }
    },
    imgHeightStyled: function () {
      switch (this.styling) {
        case 'default':
          return this.imgHeight == null ? '81px' : this.imgHeight
        case 'minimal':
          return this.imgHeight == null ? '46px' : this.imgHeight
        default:
          return '81px'
      }
    },
    timeAgoLabel: function () {
      const rtf1 = new Intl.RelativeTimeFormat('ca', { style: 'narrow' })
      const now = new Date()
      const datePosted = Date.parse(this.subtitle.updated_at)
      const diff = Math.abs(now - datePosted)
      const diffHours = Math.ceil(diff / (1000 * 60 * 60))
      if (diff > (1000 * 60 * 60) && diffHours < 24) {
        return rtf1.format(-diffHours, 'hour')
      } else if (diffHours > 24) {
        const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24))
        return rtf1.format(-diffDays, 'day')
      }
      return this.$t('fa_un_moment')
    },
    localizedDate: function () {
      const date = new Date(this.subtitle.updated_at)
      return date.toLocaleString()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.media-card {
  display: flex;
  padding: 8px;
  box-sizing: border-box;
  background: white;
  box-shadow: 0 0 4px #ccc;
  transition: box-shadow 0.3s;
  cursor: pointer;

  &.default{
    margin-bottom: 10px;
    border-radius: 4px;
  }

  &:hover {
    box-shadow: 0 2px 8px #ccc;
  }
  img {
    border-radius: 3px;
    background: #CCC;
    height: 81px;
    width: 54px;
  }
  .card-info{
    padding-left: 15px;
    flex: 1;
    position: relative;
  }
  &.default .title {
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      margin-bottom: 4px;
    }
    h2 {
      flex: 1;
      @media (max-width: 768px) {
        font-size: 1.1em;
      }
    }
    .episode {
      font-size: 0.8em;
      padding: 3px 8px;
      border: 1px solid #999;
      border-radius: 4px;
      @media (max-width: 768px) {
        display: inline;
        text-align: center;
        font-size: 2.2vw;
        padding: 5px 0;
        border-radius: 14px;
        width: 24px;
        height: 24px;
        border: 1px solid #999;
        color: #999;
        font-weight: bold;
        box-sizing: border-box;
      }
    }
  }
  &.minimal h2 {
    font-size: 1em;
  }
  &.minimal .media-type {
    display: none;
  }

  .media-time-ago {
    position: absolute;
    right: 0px;
    bottom: 0px;
    font-size: 0.7em;
    color: #999;
  }

}
</style>
